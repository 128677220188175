import React from "react";
import classNames from "classnames";

import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from "@components/icons";

type NumberInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "onChange"
> & {
  widthClassName?: string;
  inputClassName?: string;
  value?: number;
  onChange?: (newValue: number | string) => void;
  label?: string;
  tooltipText?: string;
  groupText?: string;
  hasArrows?: boolean;
  defaultWidth?: boolean;
  hasMax?: boolean;
  step?: number;
  transformValue?: (n: number) => number;
};
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      className,
      widthClassName = "tw-w-[54px]",
      inputClassName,
      value,
      onChange,
      label,
      tooltipText,
      groupText,
      hasArrows,
      disabled,
      children,
      step = 1,
      transformValue,
      ...props
    },
    ref
  ) => {
    const id = React.useId();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    };

    const handleClickIncrement = () => {
      const calculatedValue = Number(value ?? 0) + step;
      const newValue = transformValue ? transformValue(calculatedValue) : calculatedValue;
      onChange?.(newValue);
    };

    const handleClickDecrement = () => {
      const calculatedValue = Number(value ?? 0) - step;
      const newValue = transformValue ? transformValue(calculatedValue) : calculatedValue;
      onChange?.(newValue);
    };

    const disableMinus = props.min != null && Number(value ?? 0) <= +props.min;
    const disablePlus = props.max != null && Number(value ?? 0) >= +props.max;

    return (
      <div
        className={classNames("tw-flex tw-flex-col", className, {
          "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-60 ": disabled
        })}
      >
        {label && (
          <label
            className={classNames("tw-mb-1 tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium", {
              ["tw-text-neutral-400"]: disabled
            })}
            htmlFor={id}
          >
            {label}

            {tooltipText && (
              <ToolTip text={tooltipText} place="top">
                <InformationCircleIcon
                  height={16}
                  width={16}
                  className="tw-ml-2 tw-cursor-pointer tw-stroke-neutral-500"
                />
              </ToolTip>
            )}
          </label>
        )}

        <div
          className={classNames("tw-flex tw-flex-row tw-items-center", {
            "tw-text-neutral-400": disabled
          })}
        >
          <div className={classNames("tw-relative tw-flex tw-items-center", widthClassName)}>
            <input
              type="number"
              className={classNames(
                "bootstrap-form-control tw-rounded-md tw-text-sm tw-font-normal [appearance:textfield]",
                {
                  "tw-text-neutral-400": disabled
                },
                inputClassName
              )}
              id={id}
              disabled={disabled}
              value={value}
              onChange={handleInputChange}
              step={step}
              ref={ref}
              {...props}
            />
            {hasArrows && (
              <div className="tw-absolute tw-right-2 tw-z-10 tw-flex tw-grow tw-select-none tw-flex-col tw-items-center">
                <ChevronUpIcon
                  className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
                    "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20": disabled || disablePlus
                  })}
                  onClick={handleClickIncrement}
                />
                <ChevronDownIcon
                  className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
                    "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20": disabled || disableMinus
                  })}
                  onClick={handleClickDecrement}
                />
              </div>
            )}
          </div>
          {groupText && <p className="tw-ml-2 tw-text-xs tw-font-medium">{groupText}</p>}
          {children}
        </div>
      </div>
    );
  }
);
