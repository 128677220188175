import React from "react";
import classNames from "classnames";

export type NewBadgeVariant =
  // hard
  | "primary"
  | "secondary"
  | "success"
  | "destructive"
  | "ghost"
  | "warning"
  | "aux"
  | "aux-2"
  // soft
  | "primary-soft"
  | "secondary-soft"
  | "success-soft"
  | "warning-soft"
  | "destructive-soft"
  | "ghost-soft"
  | "aux-soft"
  | "aux-2-soft"
  // other
  | "inherit";

interface NewBadgeProps {
  variant?: NewBadgeVariant;
  size?: "20" | "24" | "28";
  className?: string;
  children?: React.ReactNode;
  label: string;
  onClick?: () => void;
  dot?: boolean;
  icon?: JSX.Element;
  rIcon?: JSX.Element;
  corner?: "rounded" | "square";
}
export const NewBadge: React.FC<NewBadgeProps> = ({
  variant = "primary",
  size = "20",
  className,
  children,
  label,
  onClick,
  dot,
  icon,
  rIcon,
  corner = "square"
}) => {
  const renderIcon = (icon: JSX.Element, className: string) => {
    className = classNames(icon.props.className, className);
    const props = { ...icon.props, className };
    return <icon.type {...props} />;
  };

  const Dot = dot ? <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-success-500" /> : null;

  const iconClassname = classNames("tw-w-4 tw-h-4", {
    "tw-text-neutral-700": variant === "secondary-soft"
  });

  const LeftIcon = icon ? renderIcon(icon, iconClassname) : null;
  const RightIcon = rIcon ? renderIcon(rIcon, iconClassname) : null;

  return (
    <div
      className={classNames(
        "tw-min-w[6px] tw-flex tw-w-fit tw-select-none tw-flex-row tw-items-center tw-gap-1 tw-px-2 tw-text-center tw-transition-all tw-ease-in-out",
        {
          "tw-rounded-full": corner === "rounded",
          "tw-rounded-4": corner === "square",
          "tw-border tw-border-neutral-200": ["primary", "secondary-soft"].includes(variant),
          // hard
          "tw-bg-primary-500 tw-text-white": variant === "primary",
          "tw-bg-neutral-700 tw-text-white": variant === "secondary",
          "tw-bg-success-600 tw-text-white": variant === "success",
          "tw-bg-destructive-600 tw-text-white": variant === "destructive",
          "tw-bg-neutral-100 tw-text-neutral-700": variant === "ghost",
          "tw-bg-warning-600 tw-text-white": variant === "warning",
          "tw-bg-aux-500 tw-text-white": variant === "aux",
          "tw-bg-aux-2-600 tw-text-white": variant === "aux-2",
          // soft
          "tw-bg-primary-50 tw-text-primary-800": variant === "primary-soft",
          "tw-text-neutral-900": variant === "secondary-soft",
          "tw-bg-success-100 tw-text-success-800": variant === "success-soft",
          "tw-bg-destructive-100 tw-text-destructive-800": variant === "destructive-soft",
          "tw-text-neutral-700": variant === "ghost-soft",
          "tw-bg-warning-100 tw-text-warning-800": variant === "warning-soft",
          "tw-bg-aux-50 tw-text-aux-800": variant === "aux-soft",
          "tw-bg-aux-2-100 tw-text-aux-2-700": variant === "aux-2-soft",
          "tw-h-7 tw-py-1 tw-text-sm": size === "28",
          "tw-h-6 tw-py-0.5 tw-text-sm": size === "24",
          "tw-h-5 tw-text-xs": size === "20"
        },
        className
      )}
      onClick={onClick}
    >
      {Dot}
      {LeftIcon}
      {Boolean(children) && children}
      {Boolean(label) && <span className="tw-font-medium">{label}</span>}
      {RightIcon}
    </div>
  );
};
