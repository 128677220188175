import React from "react";

export interface TableContext {
  columns: number;
}
export const TableContext = React.createContext<TableContext | null>(null);
interface TableProviderProps extends TableContext {
  children: React.ReactNode;
}
export const TableProvider: React.FC<TableProviderProps> = ({ columns, children }) => {
  return <TableContext.Provider value={{ columns }}>{children}</TableContext.Provider>;
};
export const useTableContext = () => {
  const context = React.useContext(TableContext);

  if (context === null) {
    throw new Error("useTableContext must be used within a TableProvider");
  }

  return context;
};
