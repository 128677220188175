import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { getUsageOverview } from "@frontend/api/usage.service";
import { StorageCard } from "@frontend/components/usage/storage-card";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_USAGE_TRANSACTIONS_PATH } from "@frontend/routes";

import { NewTable } from "@components/new-table";
import { TextButton } from "@components/text-button";

export const UsageOverviewPage: React.FC = () => {
  const accountId = getAccountId();
  const [hasLoadedData, setLoadedData] = React.useState(false);
  const [totalMinutes, setTotalMinutes] = React.useState(0);
  const [usedMinutes, setUsedMinutes] = React.useState(0);
  const [totalStorage, setTotalStorage] = React.useState(0);
  const [usedStorage, setUsedStorage] = React.useState(0);

  React.useEffect(() => {
    getUsageOverview({
      accountId
    }).then((response) => {
      setLoadedData(true);
      if (response) {
        setTotalMinutes(response.totalMinutes);
        setUsedMinutes(response.usedMinutes);
        setTotalStorage(response.totalStorageBytes);
        setUsedStorage(response.usedStorageBytes);
      }
    });
  }, []);

  if (!accountId || !hasLoadedData || !config.features.usageStats) return;

  const items = [
    {
      item: "AI transcription & translation",
      unit: "Minutes",
      total: totalMinutes,
      usage: usedMinutes
    }
  ];

  return (
    <>
      <SettingsPageHeader title="Usage" description="Keep track of the usage of your account." className="tw-mb-6" />
      <StorageCard totalStorage={totalStorage} usedStorage={usedStorage} className="tw-mb-6 tw-min-w-100" />
      <h3 className="tw-mb-2 tw-flex tw-gap-1 tw-text-lg tw-font-semibold tw-text-neutral-900">Credits used</h3>
      <NewTable
        columns={[
          { label: "Type", id: "type", width: "1fr" },
          { label: "Total", id: "unit", width: "1fr" },
          { label: "Used", id: "used", width: "1fr" },
          { label: "", id: "view", width: "1fr" }
        ]}
        items={items}
        className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
      >
        <NewTable.Header>
          {(cols) =>
            cols.map((col, i) => {
              const isFirst = i === 0;
              const isLast = cols.length - 1 === i;

              return (
                <NewTable.HeaderCell
                  key={col.id}
                  className={classNames(" !tw-pr-2 tw-font-medium", {
                    "!tw-pl-3": isFirst,
                    "!tw-pl-2": !isFirst,
                    "!tw-pr-3": isLast,
                    "!tw-pr-2": !isLast
                  })}
                >
                  {col.label}
                </NewTable.HeaderCell>
              );
            })
          }
        </NewTable.Header>
        {items.map((item, i) => (
          <Row
            key={i}
            type={item.item}
            total={item.total}
            used={item.usage}
            link={SETTINGS_USAGE_TRANSACTIONS_PATH + "?reason=transcribe,translate"}
          />
        ))}
      </NewTable>
    </>
  );
};

interface MediaRowInnerProps {
  type: string;
  total: number;
  used: number;
  link: string;
}
const Row: React.FC<MediaRowInnerProps> = ({ type, total, used, link }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-cursor-pointer";
    if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    return classes;
  }, [isHovered]);

  const navigate = useNavigate();
  const onClickCell = () => navigate(link);

  return (
    <>
      <NewTable.Cell
        className={classNames(sharedClasses, "!tw-pl-3")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {type}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {total}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {used}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "!tw-pr-3")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <TextButton variant="secondary" size="sm" onClick={onClickCell} className="tw-ml-auto tw-mr-2 tw-w-[60px]">
          View
        </TextButton>
      </NewTable.Cell>
    </>
  );
};
