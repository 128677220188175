import React from "react";
import SubtitlesOctopus, { SubtitlesOctopusOptions } from "libass-wasm";

import { generateAss, MediaConfig, Transcription } from "@getsubly/common";
import { useDebounce } from "@media-player/hooks/use-debounce";

const LOADING_CUE = {
  id: "loading",
  start: 0,
  end: 10,
  children: [{ text: "Loading...", start: 0, end: 10 }]
};

interface SubtitlesCanvasProps {
  canvasWidth: number;
  canvasHeight: number;
  isAudio: boolean;
  isLoaded: boolean;
  hasArtwork: boolean;
  currentTime: number;
  src?: string;
  mediaConfig: MediaConfig;
  transcription: Transcription;
  fonts: string[];
  onSubtitlesReady?: (ready: boolean) => void;
}
export const SubtitlesCanvas: React.FC<SubtitlesCanvasProps> = ({
  canvasWidth,
  canvasHeight,
  isAudio,
  isLoaded,
  hasArtwork,
  currentTime,
  src,
  mediaConfig,
  transcription,
  fonts,
  onSubtitlesReady
}) => {
  const subCanvasEl = React.useRef<HTMLCanvasElement>(null);
  const [subPlayer, setSubPlayer] = React.useState<SubtitlesOctopus>();
  const [captions, setCaptions] = React.useState<string>();
  const [hasInitialised, setInitialised] = React.useState<boolean>(false);

  const width = useDebounce(canvasWidth, 300);
  const height = useDebounce(canvasHeight, 300);

  const onReady = () => {
    if (!hasInitialised) {
      setInitialised(true);
      onSubtitlesReady?.(true);
    }
  };

  const isAudioWithoutArt = Boolean(isAudio && !hasArtwork);

  const getFinalTranscription = (transcription: Transcription, hasInitialised: boolean) => {
    if (hasInitialised) {
      return transcription;
    }

    if (transcription?.[0]?.start === 0) {
      return transcription;
    }

    return [LOADING_CUE, ...transcription];
  };

  React.useEffect(() => {
    if (!transcription || !mediaConfig) {
      return;
    }

    const finalTranscription = getFinalTranscription(transcription, hasInitialised);

    const ass = generateAss(finalTranscription, mediaConfig);
    setCaptions(ass);

    subPlayer?.setTrack(ass);
  }, [transcription, mediaConfig, subPlayer, hasInitialised]);

  // Only run this effect when it does NOT have a player!
  React.useEffect(() => {
    if (subPlayer) {
      return;
    }

    const canvas = subCanvasEl?.current;

    if (!canvas || !isLoaded || !captions || isAudioWithoutArt) {
      return;
    }

    const options: SubtitlesOctopusOptions = {
      subContent: captions,
      workerUrl: "/js/libass/subtitles-octopus-worker.js",
      fonts,
      canvas,
      onReady
    };

    const newPlayer = new SubtitlesOctopus(options);

    setSubPlayer(newPlayer);
  }, [src, isLoaded, subPlayer, captions, isAudioWithoutArt]);

  // Dispose and create a new player if number of fonts change
  // This happens if we add a non-latin font (eg. Japanese)
  React.useEffect(() => {
    if (!subPlayer || !fonts.length) {
      return;
    }

    subPlayer.dispose();

    const canvas = subCanvasEl?.current;

    if (!canvas || !isLoaded || !captions || !subPlayer) {
      return;
    }

    const options: SubtitlesOctopusOptions = {
      subContent: captions,
      workerUrl: "/js/libass/subtitles-octopus-worker.js",
      fonts,
      canvas,
      onReady
    };

    const newPlayer = new SubtitlesOctopus(options);
    setSubPlayer(newPlayer);
  }, [fonts.length]);

  // Update canvas that render the subtitles with the video size and aspect ratio
  React.useEffect(() => {
    if (!subPlayer) {
      return;
    }

    const pixelRatio = Math.max(window.devicePixelRatio, 2);
    if (width && height) {
      subPlayer.resize(width * pixelRatio, height * pixelRatio);
    }
  }, [subPlayer, isLoaded, width, height]);

  // Update subtitles player time
  React.useEffect(() => {
    if (!subPlayer || !isLoaded) {
      return;
    }

    subPlayer?.setCurrentTime(currentTime);
  }, [isLoaded, subPlayer, currentTime]);

  // Clean up for performance
  React.useEffect(() => {
    if (!subPlayer) {
      return;
    }

    return () => subPlayer.dispose();
  }, [subPlayer]);

  // Also remove subtitles player when it is an audio without artwork
  React.useEffect(() => {
    if (!subPlayer || !isAudioWithoutArt) {
      return;
    }

    return () => {
      subPlayer.dispose();
      setSubPlayer(undefined);
    };
  }, [subPlayer, isAudioWithoutArt]);

  if (isAudio && !hasArtwork) {
    return null;
  }

  return (
    <canvas
      id="subtitles-canvas"
      className="tw-pointer-events-none tw-absolute tw-top-0 tw-block tw-h-full tw-w-full"
      ref={subCanvasEl}
    />
  );
};
