import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@components/button";
import { Input } from "@components/form-controls";

import { passwordPattern } from "@core/utils/regex-patterns";

import { PasswordRules } from "../password-rules/password-rules";
import { TermsCheckbox } from "../terms-checkbox/terms-checkbox";

export interface SetPasswordForm {
  password: string;
}

interface SetPasswordProps {
  loading: boolean;
  onSubmit: (formData: SetPasswordForm) => void;
}

export const SetPassword: React.FC<SetPasswordProps> = ({ loading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<SetPasswordForm>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const handleFormSubmit = (data: SetPasswordForm) => {
    if (!termsAccepted) {
      setShowTermsError(true);
      return;
    }

    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="auth-form">
      <h2 className="title tw-text-h2">Choose your password to access Subly</h2>
      <div className="form-container">
        <div className="bootstrap-form-group">
          <label htmlFor="email">Password</label>

          <Input
            placeholder="Input your password"
            hasError={!!errors.password}
            type="password"
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />
          <PasswordRules value={watch("password")} />
        </div>

        <TermsCheckbox accepted={termsAccepted} onAccepted={setTermsAccepted} showError={showTermsError} />
        <Button variant="primary" className="bootstrap-btn-block tw-mt-4" type="submit" loading={loading} size="36">
          Complete Registration
        </Button>
      </div>
    </form>
  );
};
