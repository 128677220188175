import React from "react";
import classNames from "classnames";

type TableHeadItem = {
  name: string | React.ReactNode;
  className?: string;
};

interface TableProps {
  responsive?: boolean;
  striped?: boolean;
  header?: (string | TableHeadItem)[];
  className?: string;
  children: React.ReactNode;
}

export const Table: React.FC<TableProps> = ({ responsive, header, striped, className, children }) => {
  return (
    <TableContainer className={classNames({ "table-striped": striped }, className)} responsive={responsive}>
      <TableHeader header={header} />
      {children}
    </TableContainer>
  );
};

interface TableContainerProps {
  responsive?: boolean;
  className?: string;
  children: React.ReactNode;
}
const TableContainer: React.FC<TableContainerProps> = ({ responsive, className, children }) => {
  return (
    <div className={classNames({ "table-responsive": responsive })}>
      <table className={classNames("table", className)}>{children}</table>
    </div>
  );
};

interface TableHeaderProps {
  header?: (string | TableHeadItem)[];
  className?: string;
}
const TableHeader: React.FC<TableHeaderProps> = ({ header }) => {
  if (!header?.length) {
    return null;
  }

  const renderHeader = header.map((headItem, i) => {
    const content = typeof headItem === "string" ? headItem : headItem.name;
    const className = typeof headItem === "string" ? undefined : headItem.className;

    return (
      <th scope="col" key={i} className={className}>
        {content}
      </th>
    );
  });

  return (
    <thead>
      <tr>{renderHeader}</tr>
    </thead>
  );
};
