import React, { useCallback } from "react";
import classNames from "classnames";
import { Accept, useDropzone } from "react-dropzone";

import { Upload2LineIcon, UploadCloud2LineIcon } from "@components/icons";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";

interface FileDropCardProps {
  className?: string;
  activeClassName?: string;
  multiple?: boolean;
  dropzoneAccept: Accept;
  disabled?: boolean;
  onDropFiles: (files: File[]) => void;
  children?: (props: {
    isDragAccept: boolean;
    isDragReject: boolean;
    disabled: boolean;
    open: () => void;
  }) => React.ReactNode;
}

const Container: React.FC<FileDropCardProps> = ({
  className,
  activeClassName = "",
  multiple,
  dropzoneAccept,
  disabled = false,
  onDropFiles,
  children
}) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const onDrop = useCallback(
    (files: File[]) => {
      if (!files?.length) return;

      const eventName = files.length > 1 ? "Select multiple files" : "Select standard file";
      trackEventWithAuth(eventName);

      onDropFiles(files);
    },
    [onDropFiles, trackEventWithAuth]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } = useDropzone({
    onDrop,
    accept: dropzoneAccept,
    minSize: 0,
    multiple,
    noClick: true,
    disabled
  });

  return (
    <div
      className={classNames(
        "tw-flex tw-min-h-[222px] tw-cursor-default tw-flex-col tw-items-center tw-justify-center tw-rounded-[10px] tw-border-2 tw-border-dashed tw-border-transparent tw-bg-[#eeeeee] tw-outline-none",
        className,
        {
          "!tw-border-primary-500 !tw-bg-primary-50": isDragAccept,
          "!tw-border-destructive-500 !tw-bg-destructive-50": isDragReject,
          [activeClassName]: isDragAccept && activeClassName
        }
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragAccept ? <DefaultHoverFileDropView /> : children?.({ isDragAccept, isDragReject, disabled, open })}
    </div>
  );
};

type DefaultFileDropViewProps = {
  disabled: boolean;
  onClick: () => void;
};
export const DefaultFileDropView: React.FC<DefaultFileDropViewProps> = ({ disabled, onClick }) => {
  const handleClickUpload = (e: React.MouseEvent) => {
    e.preventDefault();
    if (disabled) return;
    onClick();
  };
  return (
    <div className="tw-mx-auto tw-flex tw-w-full tw-max-w-md tw-flex-col tw-items-center tw-gap-y-2 tw-px-4 tw-text-center">
      <Upload2LineIcon className="tw-h-9 tw-w-9 tw-text-neutral-500" />
      <p className="tw-m-0 tw-text-xs tw-leading-[1.4] tw-text-neutral-500">
        Drag and Drop files here <br />
        or{" "}
        <a
          href="#"
          className={classNames("tw-font-normal", {
            "tw-text-primary-500 hover:tw-text-primary-700": !disabled,
            "tw-cursor-not-allowed tw-text-neutral-400 hover:tw-text-neutral-400": disabled
          })}
          onClick={handleClickUpload}
        >
          select files
        </a>{" "}
        to upload
      </p>
    </div>
  );
};

export const DefaultHoverFileDropView = () => {
  return (
    <div className="tw-mx-auto tw-flex tw-w-full tw-max-w-md tw-flex-col tw-items-center tw-gap-y-2 tw-px-4 tw-text-center">
      <UploadCloud2LineIcon className="tw-h-9 tw-w-9 tw-text-neutral-500" />
      <p className="tw-m-0 tw-text-xs tw-leading-[1.4] tw-text-neutral-900">Drop it like it's hot</p>
    </div>
  );
};

type FileAcceptanceMessageProps = {
  formats: string[];
};
export const FileAcceptanceMessage: React.FC<FileAcceptanceMessageProps> = ({ formats }) => {
  let message = "We currently only accept ";
  if (formats.length === 0) {
    message = "We currently do not accept any file formats.";
  } else if (formats.length === 1) {
    message += formats[0] + " files.";
  } else {
    message += formats.slice(0, -1).join(", ") + " or " + formats.slice(-1) + " files.";
  }
  return <p className="tw-mb-0 tw-mt-2.5 tw-text-xs tw-font-normal">{message}</p>;
};

export const FileDropCard = Object.assign(Container, {
  DefaultFileDropView,
  DefaultHoverFileDropView,
  FileAcceptanceMessage
});
