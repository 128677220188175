import classNames from "classnames";

import { ToolTip } from "@frontend/containers/tooltip/tooltip";

import { ColorPickerIcon } from "@components/color-picker/color-picker-icon";
import { Input } from "@components/form-controls";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BackgroundFontColorIcon,
  BackgroundFontColorMeshIcon,
  BoldIcon,
  FontColorIcon,
  ItalicsIcon,
  UnderlineIcon
} from "@components/icons";

import { assColorToHex, AssStyle, hexToAssColor, MediaConfig } from "@getsubly/common";
import { ToolbarButton } from "@media-editor/components/buttons";
import { FontDropdown } from "@media-editor/components/fonts/font-dropdown";
import { FontSizeDropdown } from "@media-editor/components/fonts/font-size-dropdown";

interface TextStylesProps {
  value?: AssStyle;
  onUpdateStyleValue: (value: Partial<AssStyle>) => void;
  disableTabIndex?: boolean;
  hideLabels?: boolean;
  className?: string;
}

export const TextStyles: React.FC<TextStylesProps> = ({
  value,
  onUpdateStyleValue,
  disableTabIndex,
  hideLabels,
  className
}) => {
  if (!value) {
    return null;
  }

  const updateValues = (values: Partial<AssStyle>) => {
    onUpdateStyleValue({ ...value, ...values });
  };

  return (
    <div className={classNames("tw-flex tw-flex-col", className)}>
      <div className="tw-mb-4 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
        <div className="tw-flex tw-flex-grow tw-flex-col">
          {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Font</p>}
          <FontDropdown value={value.font} onChange={updateValues} />
        </div>
        <div className="tw-flex tw-flex-col">
          {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Size</p>}
          <FontSizeDropdown value={value.fontSize} onChange={(fontSize) => updateValues({ fontSize })} />
        </div>
      </div>

      <div className="tw-flex tw-flex-row tw-gap-6">
        <EmphasisTextStyles
          value={value}
          onUpdate={updateValues}
          disableTabIndex={disableTabIndex}
          hideLabels={hideLabels}
        />
        <AlignmentTextStyles
          value={value}
          onUpdate={updateValues}
          disableTabIndex={disableTabIndex}
          hideLabels={hideLabels}
        />
        <AngleInput value={value} onUpdate={updateValues} hideLabels={hideLabels} />
      </div>
    </div>
  );
};

interface EmphasisTextStylesProps {
  value: Partial<AssStyle>;
  onUpdate: (value: Partial<AssStyle>) => void;
  disableTabIndex?: boolean;
  hideLabels?: boolean;
}
export const EmphasisTextStyles: React.FC<EmphasisTextStylesProps> = ({
  value,
  onUpdate,
  disableTabIndex,
  hideLabels
}) => {
  return (
    <div className="tw-flex tw-flex-col">
      {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Emphasis</p>}
      <div className="tw-flex tw-flex-row tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolTip text="Text style bold" tipClassName="tw-flex-1">
          <BoldInput
            aria-labelledby="Text style bold"
            value={value.bold}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ bold: v })}
          />
        </ToolTip>
        <ToolTip text="Text style italic" tipClassName="tw-flex-1">
          <ItalicInput
            aria-labelledby="Text style italic"
            value={value.italic}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ italic: v })}
          />
        </ToolTip>
        <ToolTip text="Text style underline" tipClassName="tw-flex-1">
          <UnderlineInput
            aria-labelledby="Text style underline"
            value={value.underline}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ underline: v })}
          />
        </ToolTip>
      </div>
    </div>
  );
};

interface BoldInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const BoldInput: React.FC<BoldInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <BoldIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};

interface ItalicInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const ItalicInput: React.FC<ItalicInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <ItalicsIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};

interface UnderlineInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const UnderlineInput: React.FC<UnderlineInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <UnderlineIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};

interface AlignmentTextStylesProps {
  value: AssStyle;
  onUpdate: (value: Partial<MediaConfig>) => void;
  disableTabIndex?: boolean;
  hideLabels?: boolean;
}
export const AlignmentTextStyles: React.FC<AlignmentTextStylesProps> = ({
  value,
  onUpdate,
  disableTabIndex,
  hideLabels
}) => {
  const alignmentValue = value.alignment - Math.floor((value.alignment - 1) / 3) * 3;
  const positionValue = Math.floor((value.alignment - 1) / 3);

  const updateValues = (values: Partial<AssStyle>) => {
    onUpdate({ ...value, ...values });
  };

  const handlePosition = (alignment?: number, location?: number) => {
    if (!alignment && alignment !== 0) {
      alignment = alignmentValue;
    }

    if (!location && location !== 0) {
      location = positionValue;
    }

    const value = alignment + location * 3;

    updateValues({
      alignment: value
    });
  };

  return (
    <div className="tw-flex tw-flex-col">
      {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Alignment</p>}
      <div className="tw-flex tw-flex-row tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolTip text="Text align left">
          <ToolbarButton
            active={alignmentValue === 1}
            aria-labelledby="Text align left"
            onClick={() => handlePosition(1)}
            disableTabIndex={disableTabIndex}
          >
            <AlignLeftIcon className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
        <ToolTip text="Text align center">
          <ToolbarButton
            active={alignmentValue === 2}
            aria-labelledby="Text align center"
            onClick={() => handlePosition(2)}
            disableTabIndex={disableTabIndex}
          >
            <AlignCenterIcon className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
        <ToolTip text="Text align right">
          <ToolbarButton
            active={alignmentValue === 3}
            aria-labelledby="Text align right"
            onClick={() => handlePosition(3)}
            disableTabIndex={disableTabIndex}
          >
            <AlignRightIcon className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
      </div>
    </div>
  );
};

interface AngleInputProps {
  value: AssStyle;
  onUpdate: (value: Partial<MediaConfig>) => void;
  hideLabels?: boolean;
}
export const AngleInput: React.FC<AngleInputProps> = ({ value, onUpdate, hideLabels }) => {
  const handleChange = (angle: number | string) => {
    angle = Number(angle);

    if (angle > 360) angle = 360;
    if (angle < -360) angle = -360;

    onUpdate({ ...value, angle: angle * -1 });
  };

  return (
    <div className="tw-flex tw-flex-col">
      {hideLabels !== true ? <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Angle (°)</p> : null}

      <Input
        leftIcon={
          <div className="tw-flex tw-flex-row tw-items-center">
            <div
              className="tw-flex tw-h-4 tw-w-4 tw-rounded-full tw-border-2 tw-border-neutral-700 tw-bg-neutral-700"
              style={{
                transform: `rotate(${value.angle * -1 - 90}deg)`
              }}
            >
              <div className="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-0.5 tw-w-1/2 tw-translate-y-[-1px] tw-bg-white" />
            </div>
          </div>
        }
        type="number"
        hasArrows
        value={value.angle * -1}
        onChange={({ target }) => handleChange(Number(target.value))}
        min={-360}
        max={360}
      />
    </div>
  );
};

type ColorTextProp = Pick<AssStyle, "colorPrimary" | "colorOutline">;
interface ColorTextStylesProps {
  value: ColorTextProp;
  onUpdate: (value: Partial<ColorTextProp>) => void;
  primaryColors: string[];
  outlineColors: string[];
  addColor: (color: string) => void;
  removeColor: (type: "primaryColors" | "outlineColors", color: string) => void;
}
export const ColorTextStyles: React.FC<ColorTextStylesProps> = ({
  value,
  onUpdate,
  primaryColors,
  outlineColors,
  addColor,
  removeColor
}) => {
  const primary = assColorToHex(value.colorPrimary);
  const background = assColorToHex(value.colorOutline);

  return (
    <div className="tw-flex tw-flex-col">
      <p className="tw-mb-1 tw-text-sm tw-font-medium">Color</p>
      <div className="tw-flex tw-flex-row tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolTip text="Font color">
          <ColorPickerIcon
            color={primary}
            onChange={(c) =>
              onUpdate({
                colorPrimary: hexToAssColor(c)
              })
            }
            showAlpha
            showCustomColors
            customColors={primaryColors}
            addCustomColor={(color) => addColor(color)}
            deleteCustomColor={(color) => removeColor("primaryColors", color)}
          >
            <div>
              <FontColorIcon selectedColor={primary} height="21" />
            </div>
          </ColorPickerIcon>
        </ToolTip>
        <ToolTip text="Background color">
          <ColorPickerIcon
            color={background}
            onChange={(c) =>
              onUpdate({
                colorOutline: hexToAssColor(c)
              })
            }
            showAlpha
            showCustomColors
            customColors={outlineColors}
            addCustomColor={(color) => addColor(color)}
            deleteCustomColor={(color) => removeColor("outlineColors", color)}
          >
            <div>
              {background ? (
                <BackgroundFontColorIcon selectedColor={background} height="24" width="24" />
              ) : (
                <BackgroundFontColorMeshIcon width="24" height="24" />
              )}
            </div>
          </ColorPickerIcon>
        </ToolTip>
      </div>
    </div>
  );
};
