import React from "react";

import { DashboardBanner } from "@frontend/containers/dashboard/dashboard-banner/dashboard-banner";
import { MaintenanceBanner } from "@frontend/containers/dashboard/dashboard-banner/maintenance-banner";
import { WebviewBanner } from "@frontend/containers/dashboard/dashboard-banner/webview-banner";

interface TopBannerContainerProps {
  children: React.ReactNode;
}

export const TopBannerContainer: React.FC<TopBannerContainerProps> = ({ children }) => {
  return (
    <>
      <MaintenanceBanner />
      <WebviewBanner />
      <DashboardBanner />
      {children}
    </>
  );
};
