import React from "react";

import { getAccountTeam } from "@frontend/api/account.service";
import { loadFolders } from "@frontend/api/dashboard.service";
import {
  addMembersToUserGroup,
  deleteUserGroup,
  removeMemberFromUserGroup,
  updateUserGroup
} from "@frontend/api/user-groups.service";
import { FeaturedIcon } from "@frontend/components/featured-icon/featured-icon";
import { notificationSuccess } from "@frontend/components/notification";
import { getInitials } from "@frontend/components/profile-image-icon/profile-image-icon";
import { Prompt } from "@frontend/containers/modals/prompt";
import { TeamspaceModal } from "@frontend/containers/modals/teamspace-modal";

import { Avatar } from "@components/avatar/avatar";
import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownIconButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import {
  AddLineIcon,
  ArrowDownSLineIcon,
  ArrowRightSLineIcon,
  MeatballMenuIcon,
  TeamLineIcon
} from "@components/icons";
import { Select } from "@components/select";
import { Table } from "@components/table";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { AccountMember } from "@core/interfaces/account";
import { ModalType } from "@core/interfaces/modal-type";
import { User } from "@core/interfaces/user";
import { UserGroup } from "@core/interfaces/user-group";
import { Folder } from "@core/state/dashboard/dashboard.store";
import { useUserGroups } from "@core/state/user-groups/user-groups.hooks";
import { pluralize } from "@core/utils/strings";
import { ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

interface UserGroupsTableProps {
  filter: string;
}
export const UserGroupsTable: React.FC<UserGroupsTableProps> = ({ filter: searchQuery = "" }) => {
  const userGroups = useUserGroups() ?? [];
  React.useEffect(() => {
    getAccountTeam();
    loadFolders();
  }, []);
  const { folders } = useDashboard();

  const filterBySearchQuery = (userGroup: UserGroup) => {
    return !searchQuery || userGroup.name.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const filteredUserGroups = React.useMemo(() => {
    return userGroups.filter(filterBySearchQuery);
  }, [searchQuery, userGroups]);

  return (
    <Table columns={4} columnsWidth={["1fr", "1fr", 120, 120]}>
      <Table.Row>
        <Table.Header>Name</Table.Header>
        <Table.Header>Folders</Table.Header>
        <Table.Header>Members</Table.Header>
        <Table.Header></Table.Header>
      </Table.Row>
      {Boolean(filteredUserGroups.length) &&
        filteredUserGroups.map((userGroup) => (
          <UserGroupRow userGroup={userGroup} key={userGroup.id} folders={folders} />
        ))}
      {!Boolean(filteredUserGroups.length) && (
        <Table.Row className="tw-h-16">
          <Table.Cell className="tw-text-center">No groups</Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      )}
    </Table>
  );
};

interface UserGroupRowProps {
  userGroup: UserGroup;
  folders: Folder[];
}
const UserGroupRow: React.FC<UserGroupRowProps> = ({ userGroup, folders }) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const userGroupFolders = React.useMemo(() => {
    return folders.filter((folder) => folder.groupId === userGroup.id);
  }, [userGroup, folders]);

  const members = userGroup.members ?? [];
  const membersCount = members.length;

  const onDeleteUserGroup = async () => {
    try {
      setLoading(true);
      await deleteUserGroup(userGroup.id);
      notificationSuccess(`"${userGroup.name}" was removed successfully`);
    } finally {
      setLoading(false);
    }
  };

  const onRenameUserGroup = async () => {
    try {
      setLoading(true);

      const [showRenamePrompt, hideRenamePrompt] = useModal(ModalType.Prompt);

      showRenamePrompt(
        <Prompt
          title="Group name"
          defaultValue={userGroup.name}
          onSubmit={async (value) => {
            try {
              setLoading(true);
              await updateUserGroup(userGroup.id, {
                ...userGroup,
                name: value
              });
              notificationSuccess(`"${userGroup.name}" was renamed successfully`);
            } finally {
              setLoading(false);
            }

            hideRenamePrompt();
          }}
          onCancel={() => {
            hideRenamePrompt();
          }}
          confirmButtonText="Save"
        />
      );
    } finally {
      setLoading(false);
    }
  };

  const { currentAccount } = useAccounts();

  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroup);
  const hasPermissionToUpdate = permissions[ResourceAction.Update];
  const hasPermissionToDelete = permissions[ResourceAction.Delete];

  const [showManageTeamspaceModal, hideManageTeamspaceModal] = useModal(ModalType.ManageTeamspace);

  const handleManageTeamspace = (folder: Folder) => {
    showManageTeamspaceModal(<TeamspaceModal closeModal={hideManageTeamspaceModal} folder={folder} />);
  };

  return (
    <Table.Row onClick={() => setVisible((v) => !v)} className="tw-cursor-pointer">
      <Table.Cell className="tw-h-16 tw-text-sm tw-font-medium tw-text-neutral-700">
        <div className="tw-flex tw-items-center tw-gap-2">
          {!visible ? (
            <ArrowRightSLineIcon className="tw-h-5 tw-w-5 tw-cursor-pointer tw-select-none" />
          ) : (
            <ArrowDownSLineIcon className="tw-h-5 tw-w-5 tw-cursor-pointer tw-select-none" />
          )}
          <FeaturedIcon className="tw-mr-2" icon={<TeamLineIcon />} size="32" theme="aux-hard" />
          {userGroup.name}
        </div>
      </Table.Cell>
      <Table.Cell className="tw-h-16 tw-text-xs tw-text-neutral-600">
        {userGroupFolders.length > 0 ? (
          <Dropdown>
            <DropdownButton className="tw-z-10 tw-min-h-[32px] tw-p-1" size="28" variant="secondary" loading={loading}>
              <img src="/icons/folder_default.png" className="tw-h-4 tw-w-4 tw-shrink-0" /> {userGroupFolders.length}{" "}
              {userGroupFolders.length > 1 ? "folders" : "folder"}
            </DropdownButton>
            <DropdownMenu className="tw-min-w-0 tw-cursor-text" placement="bottom-start">
              {userGroupFolders.map((folder) => {
                return (
                  <DropdownItem key={folder.id} onClick={() => handleManageTeamspace(folder)}>
                    <img src="/icons/folder_default.png" className="tw-h-4 tw-w-4 tw-shrink-0" /> {folder.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        ) : (
          "None"
        )}
      </Table.Cell>
      <Table.Cell className="tw-h-16 tw-text-sm tw-text-neutral-800">
        {membersCount} {pluralize(membersCount, "member")}
      </Table.Cell>
      <Table.Cell className="tw-h-16 tw-justify-end">
        {hasPermissionToUpdate && hasPermissionToDelete && (
          <Dropdown>
            <DropdownIconButton
              className="tw-z-10 tw-p-1"
              size="24"
              variant="secondary"
              icon={<MeatballMenuIcon />}
              loading={loading}
            />
            <DropdownMenu className="tw-min-w-0" placement="bottom-start">
              <DropdownItem onClick={onRenameUserGroup} className="tw-flex tw-flex-row tw-items-center">
                Rename
              </DropdownItem>
              <DropdownItem
                onClick={onDeleteUserGroup}
                className="tw-flex tw-flex-row tw-items-center !tw-text-destructive-600"
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </Table.Cell>
      <Table.Details visible={visible} className="tw-cursor-auto tw-border-t tw-border-neutral-200 !tw-bg-white">
        <UserGroupDetails userGroup={userGroup} />
      </Table.Details>
    </Table.Row>
  );
};

interface UserGroupDetailsProps {
  userGroup: UserGroup;
}
const UserGroupDetails: React.FC<UserGroupDetailsProps> = ({ userGroup }) => {
  const members = userGroup.members ?? [];
  const { accountTeam } = useAccounts();
  const accountMembers = accountTeam?.members ?? [];

  return (
    <div className="tw-flex tw-flex-col tw-px-2">
      {members.map((member) => {
        return <GroupMember userGroup={userGroup} member={member} key={member.id} />;
      })}
      <div className="tw-py-2 tw-pl-12">
        <UserGroupMemberSelector userGroup={userGroup} accountMembers={accountMembers} />
      </div>
    </div>
  );
};

interface UserGroupMemberRowProps {
  userGroup: UserGroup;
  member: Pick<User, "id" | "name">;
}
const GroupMember: React.FC<UserGroupMemberRowProps> = ({ userGroup, member }) => {
  const [loading, setLoading] = React.useState(false);

  const handleRemoveMember = async () => {
    try {
      setLoading(true);
      await removeMemberFromUserGroup(userGroup.id, member.id);
      notificationSuccess(`"${member.name}" was removed from "${userGroup.name}"`);
    } finally {
      setLoading(false);
    }
  };

  const { currentAccount } = useAccounts();
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroupUser);
  const hasPermissionToRemoveUser = permissions[ResourceAction.Delete];

  return (
    <div className="tw-flex tw-h-12 tw-items-center tw-gap-2 tw-pl-14">
      <Avatar size="24" initials={getInitials(member.name, true, false)} withBorder={false} color="#000" />
      <p className="tw-flex-1 tw-text-sm tw-text-neutral-700">{member.name}</p>
      {hasPermissionToRemoveUser && (
        <Button size="20" variant="text-plain-destructive" onClick={handleRemoveMember} loading={loading}>
          <span className="tw-font-normal">Remove</span>
        </Button>
      )}
    </div>
  );
};

type UserGroupMemberSelectorProps = {
  userGroup: UserGroup;
  accountMembers: AccountMember[];
};

const UserGroupMemberSelector: React.FC<UserGroupMemberSelectorProps> = ({ userGroup, accountMembers }) => {
  const [isSelectorVisible, setSelectorVisible] = React.useState(false);
  const [selectedMembers, setSelectedMembers] = React.useState<string[]>([]);
  const [isAddingMembers, setIsAddingMembers] = React.useState(false);

  const options = React.useMemo(() => {
    return accountMembers.map((member) => {
      return {
        label: member.name,
        value: member.id,
        disabled: userGroup.members.some((m) => m.id === member.id)
      };
    });
  }, [accountMembers, userGroup.members]);

  const handleAddMembers = async (selectedUserIds: string[]) => {
    try {
      setIsAddingMembers(true);
      const selectedMembers = accountMembers.filter((member) => selectedUserIds.includes(member.id));
      await addMembersToUserGroup(userGroup.id, selectedUserIds);
      setSelectedMembers([]);
      setSelectorVisible(false);

      if (selectedMembers.length === 1) {
        notificationSuccess(`"${selectedMembers[0].name}" was added to "${userGroup.name}"`);
      } else {
        notificationSuccess(`"${selectedMembers.length}" members were added to "${userGroup.name}"`);
      }
    } finally {
      setIsAddingMembers(false);
    }
  };

  const { currentAccount } = useAccounts();
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroupUser);
  const hasPermissionToAddUser = permissions[ResourceAction.Create];

  if (!hasPermissionToAddUser) {
    return null;
  }

  if (!isSelectorVisible) {
    return (
      <Button icon={<AddLineIcon />} size="32" variant="ghost" onClick={() => setSelectorVisible(true)}>
        Add members
      </Button>
    );
  }

  return (
    <div className="tw-mb-4 tw-flex tw-w-full tw-gap-2">
      <Select
        size="28"
        multiple
        value={selectedMembers}
        options={options}
        onChange={setSelectedMembers}
        className="tw-flex-1"
        placeholder="Add user"
      />
      <Button
        size="28"
        variant="secondary"
        onClick={() => {
          handleAddMembers(selectedMembers);
        }}
        disabled={!selectedMembers.length}
        loading={isAddingMembers}
      >
        Add
      </Button>
    </div>
  );
};
