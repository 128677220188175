import React from "react";
import classNames from "classnames";

import { LoadingIcon } from "@components/loading-icon";

interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading?: boolean;
  label?: string;
  leftChildren?: React.ReactNode;
  leftLabel?: string;
  large?: boolean;
  children?: React.ReactNode;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, loading, label, leftChildren, leftLabel, large, className, ...props }, ref) => {
    const { defaultChecked, disabled } = props;

    const [checked, setChecked] = React.useState(Boolean(defaultChecked || props.checked));

    React.useEffect(() => {
      setChecked(Boolean(props.checked));
    }, [props.checked]);

    return (
      <label
        htmlFor={props.id}
        className={classNames(
          "tw-m-0 tw-flex tw-flex-row tw-items-center tw-font-medium",
          {
            "tw-cursor-pointer tw-text-neutral-900": !disabled,
            "tw-cursor-not-allowed tw-text-neutral-400": disabled,
            "tw-gap-2": !large,
            "tw-gap-3": large
          },
          className
        )}
      >
        {Boolean(leftChildren || leftLabel) && (
          <>
            {leftChildren}
            <p
              className={classNames({
                "tw-text-sm": !large,
                "tw-text-md": large
              })}
            >
              {leftLabel}
            </p>
          </>
        )}
        {loading && <LoadingIcon />}
        {!loading && (
          <>
            <input
              type="checkbox"
              className="tw-hidden"
              ref={ref}
              {...props}
              onChange={(e) => {
                if (typeof props.checked !== "boolean") {
                  setChecked(e.target.checked);
                }
                props.onChange?.(e);
              }}
              checked={checked}
            />
            <div
              className={classNames("tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded", {
                "tw-bg-primary-500": checked && !disabled,
                "tw-h-4 tw-w-4": !large,
                "tw-h-5 tw-w-5": large,
                "tw-border": !checked,
                "tw-border-neutral-300 tw-bg-white": !checked && !disabled,
                "tw-cursor-pointer": !disabled,
                "tw-cursor-not-allowed tw-border-neutral-200 tw-bg-neutral-50": disabled,
                "tw-bg-neutral-500/50": checked && disabled
              })}
              onClick={(e) => e.stopPropagation()}
            >
              {checked && (
                <svg
                  className={classNames({
                    "tw-h-[7px]": !large
                  })}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 1.5L4.63636 7.33333L1 4"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </>
        )}
        {Boolean(children || label) && (
          <>
            {children}
            {label && (
              <p
                className={classNames({
                  "tw-text-sm": !large,
                  "tw-text-md": large
                })}
              >
                {label}
              </p>
            )}
          </>
        )}
      </label>
    );
  }
);
