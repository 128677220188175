import React, { useState } from "react";
import classNames from "classnames";
import { endOfDay, format, startOfDay, startOfYear, subMonths } from "date-fns";

import { getUsageHistory, UsageTransaction, UsageTransactionReason } from "@frontend/api/usage.service";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_USAGE_PATH } from "@frontend/routes";

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import { CalendarLineIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon";
import { NewTable } from "@components/new-table";

import { useIntersection } from "@core/hooks/use-intersection";
import { useQuery } from "@core/hooks/use-query";
import { parseSecondsToText } from "@core/utils/time";

enum DateRangeOptionId {
  LastMonth = "LastMonth",
  Last3Month = "Last3Month",
  Last6Month = "Last6Month",
  LastYear = "LastYear",
  YTD = "YTD",
  Everything = "Everything"
}
type DateRangeOption = {
  id: DateRangeOptionId;
  label: string;
  start: Date;
  end: Date;
};

type SortableCols = "amount" | "createdAt";
export const UsageTransactionsPage: React.FC = () => {
  const [transactions, setTransactions] = React.useState<UsageTransaction[]>([]);
  const [sort, setSort] = React.useState<SortableCols>("createdAt");
  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [loadMoreRef, setLoadMoreRef] = React.useState<HTMLDivElement | null>(null);
  const [page, setPage] = React.useState(0);
  const limit = 10;
  const [totalTransactions, setTotalTransactions] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const hasMore = React.useMemo(() => {
    return limit * (page + 1) < totalTransactions;
  }, [totalTransactions, page]);
  const { queryParams } = useQuery();

  const dateRangePickerOptions = React.useMemo(
    () => [
      { id: DateRangeOptionId.LastMonth, label: "Last month", start: startOfDay(subMonths(new Date(), 1)), end: endOfDay(new Date()) }, // eslint-disable-line
      { id: DateRangeOptionId.Last3Month, label: "Last 3 months", start: startOfDay(subMonths(new Date(), 3)), end: endOfDay(new Date()) }, // eslint-disable-line
      { id: DateRangeOptionId.Last6Month, label: "Last 6 months", start: startOfDay(subMonths(new Date(), 6)), end: endOfDay(new Date()) }, // eslint-disable-line
      { id: DateRangeOptionId.LastYear, label: "Last year", start: startOfDay(subMonths(new Date(), 12)), end: endOfDay(new Date()) }, // eslint-disable-line
      { id: DateRangeOptionId.YTD, label: "YTD", start: startOfYear(new Date()), end: endOfDay(new Date()) }, // eslint-disable-line
      { id: DateRangeOptionId.Everything, label: "Everything", start: startOfYear(1), end: endOfDay(new Date()) } // eslint-disable-line
    ],
    []
  );

  const [range, selectRange] = React.useState({
    start: dateRangePickerOptions[0].start,
    end: dateRangePickerOptions[0].end
  });

  const transactionReasons = (queryParams.reason?.split(",") ?? []) as unknown as UsageTransactionReason[];

  const handleClickSort = (clickedSort: SortableCols) => {
    if (clickedSort !== sort) {
      setSort(clickedSort);
      setOrder("desc");
    } else {
      setOrder(order === "asc" ? "desc" : "asc");
    }
  };

  const loadMore = async (page: number) => {
    const skip = limit * page;
    const reason = transactionReasons;
    const from = format(range.start, "yyyy-MM-dd");
    const to = format(range.end, "yyyy-MM-dd");

    setLoading(true);
    const response = await getUsageHistory({
      from,
      to,
      reason,
      sort,
      order,
      limit,
      skip
    });
    setLoading(false);

    if (!response) return;

    const { data, meta } = response;

    setTransactions(page === 0 ? data : [...transactions, ...data]);
    setTotalTransactions(meta.total_items);
    setPage(page);
  };

  React.useEffect(() => {
    loadMore(0);
  }, [sort, order, range]);

  useIntersection(loadMoreRef, "100%", () => {
    loadMore(page + 1);
  });

  return (
    <>
      <SettingsPageHeader
        title="AI transcription & translation"
        description="Understand your usage"
        backUrl={SETTINGS_USAGE_PATH}
        className="tw-mb-4"
      />
      <DateRangePicker className="tw-mb-6" options={dateRangePickerOptions} onSelectRange={selectRange} />
      <NewTable
        columns={[
          { label: "Media name", id: "name", width: "1fr" },
          { label: "Description", id: "description", width: "1fr" },
          { label: "Created", id: "createdAt", width: "1fr" },
          { label: "Usage", id: "amount", width: "1fr" }
        ]}
        items={transactions}
        className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
      >
        <NewTable.Header>
          {(cols) =>
            cols.map((col, i) => {
              const isFirst = i === 0;
              const isLast = cols.length - 1 === i;
              return (
                <NewTable.HeaderCell
                  key={col.id}
                  className={classNames("!tw-pr-2 tw-font-medium", {
                    "!tw-pl-3": isFirst,
                    "!tw-pl-2": !isFirst,
                    "!tw-pr-3": isLast,
                    "!tw-pr-2": !isLast
                  })}
                  sortable={col.id === "createdAt" || col.id === "amount"}
                  isSorting={col.id === sort}
                  sortDirection={order}
                  onClickSort={() => handleClickSort(col.id as SortableCols)}
                >
                  {col.label}
                </NewTable.HeaderCell>
              );
            })
          }
        </NewTable.Header>
        {transactions.map((transaction, i) => (
          <Row transaction={transaction} key={i} />
        ))}
      </NewTable>
      {hasMore && (
        <div ref={setLoadMoreRef} className="tw-flex tw-w-full tw-justify-center">
          {isLoading && <LoadingIcon className="tw-mt-4" />}
        </div>
      )}
    </>
  );
};

type DateRangePickerProps = {
  className?: string;
  options: DateRangeOption[];
  onSelectRange: (range: { start: Date; end: Date }) => void;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ className, options, onSelectRange }) => {
  const [activeOption, setActiveOption] = useState<DateRangeOption>(options[0]);

  const handleSelectOption = (option: DateRangeOption) => {
    setActiveOption(option);
    onSelectRange({ start: option.start as Date, end: option.end as Date });
  };

  return (
    <Dropdown>
      <DropdownButton variant="secondary" size="32" className={className} raised>
        <CalendarLineIcon className="tw-h-4 tw-w-4" />
        {activeOption?.label}
      </DropdownButton>
      <DropdownMenu placement="bottom-start" showInPortal className="tw-overflow-hidden">
        {options.map((option) => {
          return (
            <DropdownItem key={option.id} onClick={() => handleSelectOption(option)}>
              {option.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

interface MediaRowInnerProps {
  transaction: UsageTransaction;
}
const Row: React.FC<MediaRowInnerProps> = ({ transaction }) => {
  const sharedClasses = React.useMemo(() => {
    return "tw-min-h-12";
  }, []);

  return (
    <>
      <NewTable.Cell className={classNames(sharedClasses, "!tw-pl-3")}>{transaction.name}</NewTable.Cell>
      <NewTable.Cell className={classNames(sharedClasses)}>{transaction.description}</NewTable.Cell>
      <NewTable.Cell className={classNames(sharedClasses)}>
        {format(new Date(transaction.createdAt), "do 'of' MMMM yyyy")}
      </NewTable.Cell>
      <NewTable.Cell className={classNames(sharedClasses)}>{parseSecondsToText(transaction.amount)}</NewTable.Cell>
    </>
  );
};
