import React from "react";
import { Link } from "react-router-dom";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

export const EN = {
  media: {
    InsufficientFunds: (
      <>
        You don't have enough balance, please{" "}
        <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}>upgrade</Link> for more minutes.
      </>
    ),
    UploadCancelled: (
      <>
        An error occurred while uploading your file. Delete file to try again, or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
        .
      </>
    ),
    ProcessingCancelled: (
      <>
        An error occurred while processing your file. Delete file to try again, or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
        .
      </>
    ),
    NotFound: <>An error occurred while loading your file.</>
  },
  error: {
    defaultMessage: (
      <>
        There was an unknown error, please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
      </>
    ),
    currencySwitch: (
      <>
        There was an error upgrading, please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
      </>
    ),
    mediaNotFound: (
      <>
        The media you are looking for does not exist or you don't have permissions to view it. Please try again or
        contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
      </>
    ),
    Empty: <>Oops, you've left this subtitle empty. Add text here or remove this subtitle.</>,
    StartTimeOverlap: (
      <>Oops, there's an overlap here in your subtitles. Edit this timestamp or it won't be included.</>
    ),
    EndTimeOverlap: <>Oops, there's an overlap here in your subtitles. Edit this timestamp or it won't be included.</>,
    StartAfterEnd: (
      <>Oops, this timestamp has an end date before its start date. Edit the timestamp or it won't be included.</>
    ),
    insufficientInterval: <>There is no enough time to create a new cue here.</>,
    enhancementError: <>There was an error improving your translation, please try again.</>,
    MaxFileDuration: <>The selected file is over the maximum duration of 4 hours.</>,
    PrivateVideo: (
      <>
        Private video,{" "}
        <a
          className="tw-underline"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/11437729055249-Cannot-upload-via-URL"
          target="_blank"
          rel="noreferrer"
        >
          please see here
        </a>{" "}
        how to make it public
      </>
    ),
    InsufficientStorageToDuplicate: (
      <>There is not enough storage to duplicate media. Delete old files to free up space, or get extra storage</>
    ),
    MaxTeamCapacity: (
      <>
        Maximum team capacity reached. <br /> Please add more seats or remove existing users
      </>
    ),
    UnsupportedURL: (
      <>
        Unsupported URL, please try another one or{" "}
        <a
          className="tw-underline"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/11437729055249-Cannot-upload-via-URL"
          target="_blank"
          rel="noreferrer"
        >
          check our guide
        </a>{" "}
        for more information.
      </>
    ),
    ForbiddenURL: (
      <>
        YouTube prohibits downloading from this URL. However, if this is your video, you can download it from your
        YouTube profile and upload it to Subly.{" "}
        <a
          className="tw-underline"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/11437729055249-Cannot-upload-via-URL"
          target="_blank"
          rel="noreferrer"
        >
          check our guide
        </a>{" "}
        for more information.
      </>
    ),
    UploadURLError: (
      <>
        There was an error with your URL, please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
      </>
    ),
    UploadFileError: (
      <>
        There was an error with your file, please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
      </>
    ),
    VimeoURLError: (
      <>
        There was an error with your Vimeo URL. Check our quick guide on{" "}
        <a
          className="tw-underline"
          target="_blank"
          rel="noreferrer"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/15076092995345-Importing-videos-from-Vimeo-links-not-working"
        >
          importing videos from Vimeo
        </a>
        . Please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
        .
      </>
    ),
    ZoomURLError: (
      <>
        There was an error with your Zoom URL. Check our quick guide on{" "}
        <a
          className="tw-underline"
          target="_blank"
          rel="noreferrer"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/10687308913681-Can-I-upload-my-videos-from-Zoom-"
        >
          importing videos from Zoom
        </a>
        . Please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
        .
      </>
    ),
    URLError: (
      <>
        There was an error with your URL. Check our quick guide on{" "}
        <a
          className="tw-underline"
          target="_blank"
          rel="noreferrer"
          href="https://getsublyhelp.zendesk.com/hc/en-us/articles/11437729055249-Cannot-upload-via-URL"
        >
          importing videos from URL
        </a>
        . Please try again or contact{" "}
        <a className="tw-underline" href="mailto:support@getsubly.com">
          support@getsubly.com
        </a>
        .
      </>
    ),
    MaxGlossaryTextLength: "The text length should be less than 250 characters",
    AvatarFileSize: "File size should be less than 5MB",
    AvatarImageSize: (
      <>
        Image dimensions must be at least 200x200 pixels
        <br />
        Recommended dimensions are 400x400 pixels
      </>
    )
  },
  permissions: {
    admin: {
      title: "Everything that Editor can do plus:",
      items: [
        "Can edit account billing details",
        "Can remove admin or editor users within the workspace, except the owner",
        "Can delete any media in shared folders"
      ]
    },
    editor: {
      items: [
        "Can add editors to the workspace",
        "Can invite users to view/edit their content",
        "Can add/edit/rename/delete their own medias",
        "Can add/edit any media in shared folders",
        "Can move media uploaded by them to private folders",
        "Can move media uploaded by others within shared folders only",
        "Can create new shared folders"
      ]
    },
    viewer: {
      items: ["Can view any media in shared folders"]
    }
  },
  mediaPermissions: {
    editor: {
      items: [
        "Can view, edit and delete shared media",
        "Can invite users to view or edit media",
        "Can add and remove users"
      ]
    },
    viewer: {
      items: ["Can view shared media", "Can download shared media"]
    }
  },
  shortcuts: [
    {
      title: "Show keyboard shortcuts",
      keys: [["Ctrl", "/"]],
      variation: [["Cmd", "/"]]
    },
    {
      title: "Skip forward 10 seconds",
      keys: [["L"]]
    },
    {
      title: "Toggle autoscroll",
      keys: [["Ctrl", "Alt", "S"]],
      variation: [["Cmd", "Option", "S"]]
    },
    {
      title: "Skip back 10 seconds",
      keys: [["J"]]
    },
    {
      title: "Search for word",
      keys: [["Ctrl", "F"]],
      variation: [["Cmd", "F"]]
    },
    {
      title: "Navigate to next subtitle cue",
      keys: [["Ctrl", "]"]],
      variation: [["Cmd", "]"]]
    },
    {
      title: "New subtitle cue",
      keys: [["Enter"]]
    },
    {
      title: "Navigate to previous subtitle cue",
      keys: [["Ctrl", "["]],
      variation: [["Cmd", "["]]
    },
    {
      title: "Add a break line to subtitle cue",
      keys: [["Shift", "Enter"]]
    },
    {
      title: "Undo",
      keys: [["Ctrl", "Z"]],
      variation: [["Cmd", "Z"]]
    },
    {
      title: "Start/Pause video",
      keys: [["Ctrl", "Spacebar"]],
      variation: [["Option", "Spacebar"]]
    },
    {
      title: "Redo",
      keys: [
        ["Ctrl", "Y"],
        ["Ctrl", "Shift", "Z"]
      ],
      variation: [
        ["Cmd", "Y"],
        ["Cmd", "Shift", "Z"]
      ]
    }
  ]
};
