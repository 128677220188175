import React from "react";
import classNames from "classnames";

import { Transition } from "@headlessui/react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";

import { TableContext, TableProvider, useTableContext } from "./table.context";

type ColumnWidth = number | string | undefined | null;
interface ContainerProps extends TableContext {
  columnsWidth?: ColumnWidth[];
  children: React.ReactNode;
  className?: string;
}
const Container: React.FC<ContainerProps> = ({ columns, columnsWidth = [], children, className }) => {
  const parsedColumnsWidth: ColumnWidth[] = Array(columns)
    .fill("1fr")
    .map((_, index) => {
      const value = columnsWidth[index];

      if (value === undefined || value === null) {
        return "1fr";
      } else if (typeof value === "number") {
        return `${value}px`;
      }

      return value;
    });

  const columnWidthVar = parsedColumnsWidth.join(" ");

  return (
    <TableProvider columns={columns}>
      <div
        className={className}
        style={
          {
            "--table-columns": columns,
            "--table-columns-width": columnWidthVar
          } as React.CSSProperties
        }
      >
        {children}
      </div>
    </TableProvider>
  );
};

const isHeaderOrCell = (child: React.ReactNode): child is React.ReactElement => {
  return React.isValidElement(child) && (child.type === Header || child.type === Cell);
};

const isDetails = (child: React.ReactNode): child is React.ReactElement => {
  return React.isValidElement(child) && child.type === Details;
};

type TableComponentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Row: React.FC<TableComponentProps> = ({ children, className, ...props }) => {
  const { columns } = useTableContext();

  let cells = React.Children.toArray(children).filter(isHeaderOrCell) as React.ReactNode;

  const details = React.Children.toArray(children).filter(isDetails);

  // Prevents from breaking the grid by adding empty children
  if (React.Children.count(cells) !== columns) {
    console.warn(
      `The number of children (${React.Children.count(cells)}) should match the number of columns (${columns})`
    );

    if (React.Children.count(cells) < columns) {
      cells = React.Children.toArray(cells).concat(Array(columns - React.Children.count(cells)).fill(<span />));
    } else if (React.Children.count(cells) > columns) {
      cells = React.Children.toArray(cells).slice(0, columns);
    }
  }

  if (React.Children.count(details) > 1) {
    console.warn(
      `Only one <Table.Details /> component is allowed per <Table.Row />, ${React.Children.count(details)} found`
    );
  }

  return (
    <div
      className={classNames(
        "tw-grid tw-grid-cols-[var(--table-columns-width)] tw-border-b tw-border-neutral-200",
        className
      )}
      {...props}
    >
      {cells}
      {details?.[0]}
    </div>
  );
};

interface SortingProps {
  isSorting?: boolean;
  sortDirection?: "asc" | "desc";
}
const Sorting: React.FC<SortingProps> = ({ isSorting, sortDirection }) => {
  if (!isSorting) {
    return null;
  }

  if (sortDirection === "asc") {
    return <ArrowUpIcon className="tw-h-3 tw-w-3" />;
  }

  return <ArrowDownIcon className="tw-h-3 tw-w-3" />;
};

interface HeaderProps extends SortingProps, TableComponentProps {
  sortable?: boolean;
  onSort?: () => void;
}
const Header: React.FC<HeaderProps> = ({
  sortable = false,
  isSorting = false,
  onSort,
  sortDirection,
  children,
  className,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-12 tw-items-center tw-gap-1 tw-p-2 tw-text-sm tw-font-semibold tw-text-neutral-900",
        { "tw-cursor-pointer": sortable },
        className
      )}
      onClick={(e) => {
        if (sortable) {
          onSort?.();
        }

        onClick?.(e);
      }}
      {...props}
    >
      {children}
      {sortable && <Sorting isSorting={isSorting} sortDirection={sortDirection} />}
    </div>
  );
};

const Cell: React.FC<TableComponentProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={classNames("tw-flex tw-items-center tw-gap-1 tw-p-2 tw-text-sm tw-text-neutral-700", className)}
      {...props}
    >
      {children}
    </div>
  );
};

interface DetailsProps extends TableComponentProps {
  visible?: boolean;
}
const Details: React.FC<DetailsProps> = ({ visible = false, className, onClick, children, ...props }) => {
  return (
    <Transition
      as={React.Fragment}
      show={visible}
      enter="tw-transition tw-ease-out tw-duration-200"
      enterFrom="tw-opacity-0 tw-translate-y-1"
      enterTo="tw-opacity-100 tw-translate-y-0"
      leave="tw-transition tw-ease-in tw-duration-150"
      leaveFrom="tw-opacity-100 tw-translate-y-0"
      leaveTo="tw-opacity-0 tw-translate-y-1"
    >
      <div
        className={classNames("tw-col-span-full tw-flex tw-flex-col tw-gap-2", className)}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
        {...props}
      >
        {children}
      </div>
    </Transition>
  );
};

export const Table = Object.assign(Container, { Row, Header, Cell, Details });
