import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Invitation, UserAccount } from "@core/interfaces/account";
import { accountQuery, accountStore } from "@core/state/account";
import { RoleName } from "@getsubly/common";

import { getAccountTeam } from "./account.service";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface InviteMembersResponse {
  invitations: Invitation[];
}
export const inviteMembers = async (invites: Invitation[]): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) return;

  try {
    await axios.post<InviteMembersResponse>(
      `/${accountId}/invite`,
      { invites },
      { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
    );

    getAccountTeam();
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateInvitationRole = async (id: string, role: RoleName): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(
      `/${accountId}/invitation/${id}`,
      { role },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const invitations = accountTeam.invitations?.map((i) => {
        if (i.id === id) {
          return { ...i, role };
        }

        return i;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, invitations }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const deleteInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.delete(`/${accountId}/invitation/${id}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const invitations = accountTeam.invitations?.filter((i) => i.id !== id);

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, invitations }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const resendInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/${accountId}/invitation/${id}/resend`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (error) {
    handleError(error);
  }
};

export const acceptInvitation = async (
  id: string
): Promise<
  | { joinedAccount: UserAccount; hasAvailableSeats: boolean; success: true }
  | { joinedAccount: null; hasAvailableSeats: null; success: false }
> => {
  try {
    const { joinedAccount, hasAvailableSeats } = await axios.put<
      any,
      { joinedAccount: UserAccount; hasAvailableSeats: boolean }
    >(`/invitation/${id}/accept`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
    return { joinedAccount, hasAvailableSeats, success: true };
  } catch (error) {
    handleError(error);
    return { joinedAccount: null, hasAvailableSeats: null, success: false };
  }
};
