import React from "react";
import classNames from "classnames";
import { formatDistance, intervalToDuration, subDays } from "date-fns";
import { Link } from "react-router-dom";

import { MEDIA_PATH } from "@frontend/routes";

import { Dropdown } from "@components/dropdown";

import { EnrichedMediaListItem, MediaErrorReason, MediaJob } from "@core/interfaces/media";
import { userPresenceQuery } from "@core/state/user-presence";
import { durationLessThan1Min } from "@core/utils/dates";
import { parseCreditToText } from "@core/utils/plans";
import { formatBytes } from "@core/utils/storage-size";
import { JobStatus, JobType, MediaStatus } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";

import { MediaCardTop, MediaCardUser } from "./media-card-top";

import styles from "./media-card.module.scss";

interface MediaCardProps {
  media: EnrichedMediaListItem;
}

export const MediaCard: React.FC<MediaCardProps> = ({ media }) => {
  const isMediaReady = MediaStatus.Ready === media.status;
  const hasError = MediaStatus.Failed === media.status;
  const mediaLink = isMediaReady ? `${MEDIA_PATH}/${media.mediaId}` : "#";
  const mediaDuration = media.duration ?? 0;
  const { size, units } = formatBytes(media.storageBytes ?? 0, 2);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const [activeUser] = useObservable(userPresenceQuery.selectActiveUser(media.mediaId));

  return (
    <Dropdown>
      <div
        className={classNames(styles["media-card"], {
          [styles["is-ready"]]: isMediaReady,
          [styles["is-error"]]: hasError,
          [styles["is-dropdown-open"]]: isDropdownOpen
        })}
      >
        <MediaCardUser media={media} />
        <figure
          className={classNames(styles["figure"], "tw-m-0", {
            [styles["error"]]: hasError
          })}
        >
          <MediaCardTop media={media} setDropdownOpen={(v) => setIsDropdownOpen(v)} />
        </figure>
        <Link
          to={mediaLink}
          className={classNames(styles["media-card-bottom"], {
            "tw-cursor-default": !isMediaReady
          })}
        >
          <div className="tw-flex tw-w-full tw-justify-between">
            <h6 className={classNames(styles.name, "tw-m-0 tw-truncate")}>{media.name}</h6>
            <p className={classNames(styles.info, "tw-whitespace-nowrap")}>
              {media?.storageBytes && `${size} ${units}`}
            </p>
          </div>
          <div className="tw-flex tw-justify-between">
            <div className={styles.info}>{parseCreditToText(mediaDuration)}</div>
            <div className={styles.info}>{getMediaStatus(media, Boolean(activeUser))}</div>
          </div>
        </Link>
      </div>
    </Dropdown>
  );
};
const getStatusForPendingJob = (job?: Pick<MediaJob, "type">): string => {
  switch (job?.type) {
    case JobType.Upload:
      return "Uploading...";
    case JobType.Conversion:
      return "Preparing...";
    case JobType.Transcribe:
      return "Transcribing";
    default:
      return "Queuing";
  }
};

const getMediaStatus = (media: EnrichedMediaListItem, hasActiveUser?: boolean): string => {
  const job = media.latestJob;

  if (media.reason === MediaErrorReason.InsufficientFunds) {
    return "Insufficient credit";
  }

  if (media.status === MediaStatus.Failed) {
    return "Error in processing";
  }

  if (job) {
    switch (job.status) {
      case JobStatus.Uploading:
      case JobStatus.Uploaded:
      case JobStatus.Pending:
      case JobStatus.Converting:
        return getStatusForPendingJob(job);
    }
  }

  if (hasActiveUser) {
    return "Currently being edited";
  }

  const createdDuration = intervalToDuration({
    start: new Date(media.createdAt),
    end: new Date(media.updatedAt)
  });

  if (!durationLessThan1Min(createdDuration)) {
    return "Ready to edit";
  }

  return formatDistance(subDays(new Date(media.updatedAt), 0), new Date(), {
    addSuffix: true
  });
};
