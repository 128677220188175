import React from "react";
import classNames from "classnames";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import config from "@frontend/config";
import {
  SETTINGS_API_PATH,
  SETTINGS_APPS_PATH,
  SETTINGS_BILLING_PATH,
  SETTINGS_GLOSSARY_PATH,
  SETTINGS_GROUPS_PATH,
  SETTINGS_PROFILE_EDIT_PATH,
  SETTINGS_PROFILE_PATH,
  SETTINGS_USAGE_PATH,
  SETTINGS_USERS_PATH,
  SETTINGS_WORKSPACE_PATH
} from "@frontend/routes";

import { IconButton } from "@components/button";
import { ArrowLeftLineIcon } from "@components/icons";
import { Tabs } from "@components/tabs/tabs";

import { useAccounts } from "@core/hooks/use-accounts";

type Tab = { label: string; url: string; isActive: boolean };

export const SettingsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useAccounts();

  const isNavLinkActive = (location: { pathname: string }, path: string): boolean => {
    return new RegExp(path).test(location.pathname);
  };

  const tabs = React.useMemo<Tab[]>(() => {
    return [
      {
        label: "Profile",
        url: SETTINGS_PROFILE_PATH,
        isActive: location.pathname === SETTINGS_PROFILE_PATH || isNavLinkActive(location, SETTINGS_PROFILE_EDIT_PATH)
      },
      {
        label: "Users",
        url: SETTINGS_USERS_PATH,
        isActive: isNavLinkActive(location, SETTINGS_USERS_PATH)
      },
      {
        label: "Groups",
        url: SETTINGS_GROUPS_PATH,
        isActive: isNavLinkActive(location, SETTINGS_GROUPS_PATH)
      },
      isAdmin && {
        label: "Workspace",
        url: SETTINGS_WORKSPACE_PATH,
        isActive: isNavLinkActive(location, SETTINGS_WORKSPACE_PATH)
      },
      isAdmin && {
        label: "Billing",
        url: SETTINGS_BILLING_PATH,
        isActive: isNavLinkActive(location, SETTINGS_BILLING_PATH)
      },
      config.features.apiInProduct && {
        label: "API",
        url: SETTINGS_API_PATH,
        isActive: isNavLinkActive(location, SETTINGS_API_PATH)
      },
      {
        label: "Apps",
        url: SETTINGS_APPS_PATH,
        isActive: isNavLinkActive(location, SETTINGS_APPS_PATH)
      },
      {
        label: "Glossary",
        url: SETTINGS_GLOSSARY_PATH,
        isActive: isNavLinkActive(location, SETTINGS_GLOSSARY_PATH)
      },
      config.features.usageStats && {
        label: "Usage",
        url: SETTINGS_USAGE_PATH,
        isActive: isNavLinkActive(location, SETTINGS_USAGE_PATH)
      }
    ].filter(Boolean) as Tab[];
  }, [location, isAdmin]);

  return (
    <div className="tw-p-8">
      <h1 className="tw-mb-4 tw-text-h5 tw-font-semibold">Settings</h1>
      <Tabs className="tw-mb-6 !tw-w-full tw-border-b tw-border-neutral-200">
        {tabs.map((tab, i) => (
          <Tabs.Tab
            theme="primary"
            key={i}
            label={tab.label}
            selected={tab.isActive}
            onClick={() => navigate(tab.url)}
          />
        ))}
      </Tabs>
      <Outlet />
    </div>
  );
};

type SettingsPageHeaderProps = {
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
  backUrl?: string;
};
export const SettingsPageHeader: React.FC<SettingsPageHeaderProps> = ({ className, title, description, backUrl }) => {
  return (
    <header className={classNames("tw-flex tw-flex-row tw-gap-1", className)}>
      {backUrl && (
        <IconButton
          icon={<ArrowLeftLineIcon className="!tw-h-5 !tw-w-5 tw-text-black" />}
          variant="ghost"
          size="28"
          to={backUrl}
          type="link"
        />
      )}
      <div>
        {title && <h3 className="tw-flex tw-gap-1 tw-text-lg tw-font-semibold tw-text-neutral-900">{title}</h3>}
        {description && <p className="tw-text-sm tw-text-neutral-600">{description}</p>}
      </div>
    </header>
  );
};
