import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { uploadFiles } from "@frontend/api/upload.service";
import { Alert } from "@frontend/components/alert/alert";
import settings from "@frontend/config/settings/settings";
import { setUserSettings, userLanguage } from "@frontend/config/settings/settings.service";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { ErrorWarningFillIcon } from "@components/icons";
import { Modal, ModalAlignment } from "@components/modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useMediaInfo } from "@core/hooks/use-media-info";
import { usePlan } from "@core/hooks/use-plan";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { useUpload } from "@core/hooks/use-upload";
import { SublyPlan } from "@core/interfaces/billing";
import { UploadingStatus, uploadStore } from "@core/state/upload";

import { AdvancedOptionsSection } from "./advanced-options-section";
import { CreditSection } from "./credit-section";
import { FileSection } from "./file-section";
import { LanguagePickerSection } from "./language-picker-section-get-rid";
import { SaveLocationSection } from "./save-location-section";
import { UploadOptionsSection } from "./upload-options-section";

interface UploadModalProps {
  closeModal: () => void;
}

export const DashboardUploadModal: React.FC<UploadModalProps> = ({ closeModal }) => {
  return (
    <Modal onDismiss={closeModal} size="608" alignment={ModalAlignment.Top} showCloseButton>
      <UploadModalContainer closeModal={closeModal} />
    </Modal>
  );
};

// Separated the container of the modal to a new component, so it
// doesn't rerender the whole Modal when things change
const UploadModalContainer: React.FC<UploadModalProps> = ({ closeModal }) => {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(userLanguage());
  const [languageError, setLanguageError] = useState<boolean>();
  const { plan, isProOrHigher } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { loading } = useMediaInfo();
  const { activeFolder } = useDashboard();
  const [folder, setFolder] = React.useState(activeFolder);
  React.useEffect(() => {
    if (!folder && activeFolder) setFolder(activeFolder);
  }, [activeFolder]);
  const { availableSeconds, availableStorage } = usePlan();
  const { customVocabularyWords, isViewer } = useAccounts();
  const { hasPermission: hasPermissionToUpload } = usePlanPermissions(SublyPlan.Pro); // any subscription or trial
  const { totalDuration, totalSize, stagedQueue } = useUpload();
  const { isOwner } = useAccounts();
  const navigate = useNavigate();

  const hasInsufficientCredit = totalDuration > availableSeconds;
  const hasInsufficientStorage = totalSize > availableStorage;

  React.useEffect(() => {
    return () => {
      // clear staged queue when modal is closed
      uploadStore.update((state) => {
        return {
          ...state,
          queue: state.queue.filter((file) => {
            const isStaged = [
              UploadingStatus.Analyzing,
              UploadingStatus.AnalysisError,
              UploadingStatus.Staged
            ].includes(file.uploadingStatus);
            return !isStaged;
          })
        };
      });
    };
  }, []);

  const handleSelectedLanguage = (languageCode?: string) => {
    if (languageCode) {
      setSelectedLanguageCode(languageCode);
      setUserSettings({ language: languageCode });
    }
  };

  const handleUpload = async () => {
    if (!hasPermissionToUpload) return;
    if (!selectedLanguageCode) return setLanguageError(true);

    uploadStore.clearUploadsWithErrors();
    uploadStore.update({ language: selectedLanguageCode });

    uploadFiles({
      plan,
      isProOrHigher,
      mixpanelTrack: trackEventWithAuth,
      skipTranscription: false,
      accountCustomVocabulary: customVocabularyWords.map((word) => word.word),
      folderId: folder?.id
    });

    closeModal();
  };

  const disableUpload =
    stagedQueue.filter((f) => !f.error).length === 0 ||
    loading ||
    hasInsufficientCredit ||
    hasInsufficientStorage ||
    isViewer;

  const languageErrorVisible = languageError && !selectedLanguageCode;

  return (
    <div>
      <h3 className="tw-mb-1 tw-text-lg tw-font-semibold">Upload</h3>

      <p className="tw-mb-3 tw-text-sm tw-text-neutral-600">Upload and transcribe your media here.</p>

      <FileSection className="tw-mb-4" defaultLanguageCode={selectedLanguageCode} onClickPurchaseStorage={closeModal} />

      <UploadOptionsSection className="tw-mb-4" />

      <div className="tw-mb-4 tw-flex tw-gap-4">
        <LanguagePickerSection
          languages={settings.transcription.languages}
          showRequiredError={languageErrorVisible}
          onSelect={handleSelectedLanguage}
          className="tw-flex-1"
          showInPortal={false}
        />
        <SaveLocationSection
          className="tw-min-w-0 tw-flex-1"
          folder={folder}
          setFolder={setFolder}
          showInPortal={false}
        />
      </div>

      <AdvancedOptionsSection className="tw-mb-4" />

      <hr className="tw-mb-4" />

      <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
        <CreditSection availableCredit={availableSeconds} price={totalDuration} onNavigate={closeModal} />

        {hasInsufficientStorage && (
          <Alert danger className="tw-px-6">
            <div className="tw-flex tw-items-center tw-gap-4">
              <ErrorWarningFillIcon className="tw-h-5 tw-w-5 tw-min-w-0" />
              <div className="tw-flex-1">
                Your storage limit has been reached.{" "}
                {isOwner && (
                  <>
                    {" "}
                    Please delete some files or{" "}
                    <a
                      href="#"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        navigate(SETTINGS_BILLING_PATH);
                        closeModal?.();
                      }}
                      className="tw-text-primary-500 hover:tw-text-primary-500 hover:tw-underline"
                    >
                      purchase
                    </a>{" "}
                    additional storage.
                  </>
                )}
              </div>
            </div>
          </Alert>
        )}
      </div>

      <div className="tw-mt-2 tw-flex tw-justify-center">
        <Button variant="primary" disabled={disableUpload} className="tw-w-full" onClick={handleUpload}>
          <span className="tw-flex tw-items-center tw-font-semibold">Upload</span>
        </Button>
      </div>
    </div>
  );
};
