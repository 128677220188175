import React from "react";
import classNames from "classnames";

import { CheckmarkIcon, TrashCanIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon";

import { FloatingPortal } from "@floating-ui/react";

import { GroupHeader } from "../builtins/group-header";
import { Group, Option } from "../select";
import { useSelect } from "../select.context";
import { isSelected } from "../utils/select.utils";

type SelectDropdownProps = {
  show: boolean;
  placement: "above" | "below";
  footer?: (props: { dismiss: () => void }) => React.ReactNode;
  className?: string;
  groups: Group[];
  formatGroupHeader?: (group: Group) => string | number | boolean | React.ReactNode | React.ReactNode[];
  selectionLimitReached: boolean;
  activeOption?: Option;
  selectedOptions: Option[];
  onClickOption: (value: Option) => void;
  formatOptionLabel?: (option: Option, group: Group) => string | number | boolean | React.ReactNode | React.ReactNode[];
  onDismiss: () => void;
  onDeleteOption?: (value: Option) => void;
  showInPortal?: boolean;
};

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  show,
  placement,
  footer,
  onDismiss,
  className,
  groups,
  formatGroupHeader,
  selectionLimitReached,
  activeOption,
  selectedOptions,
  onClickOption,
  onDeleteOption,
  formatOptionLabel,
  showInPortal
}) => {
  return (
    <SelectOptions
      show={show}
      placement={placement}
      footer={footer?.({ dismiss: onDismiss })}
      className={className}
      showInPortal={showInPortal}
    >
      {groups.map((group, i) => (
        <div key={i}>
          {group.label && (formatGroupHeader?.(group) ?? <GroupHeader group={group} />)}
          {group.options.map((option, j) => (
            <SelectOption
              key={`${option.value}-${j}`}
              value={option}
              disabled={option.disabled || selectionLimitReached}
              deletable={group.deletable}
              isActive={activeOption?.value === option.value}
              isSelected={isSelected(selectedOptions, option)}
              onClick={onClickOption}
              onDelete={onDeleteOption}
            >
              {formatOptionLabel?.(option, group) ?? option.label}
            </SelectOption>
          ))}
          {groups.length > i + 1 && <hr className="tw-mx-6 tw-my-3 tw-h-[1px] tw-bg-neutral-200" />}
        </div>
      ))}
    </SelectOptions>
  );
};

type SelectOptionsProps = {
  className?: string;
  show: boolean;
  placement: "above" | "below";
  footer?: React.ReactNode;
  showInPortal?: boolean;
  children: React.ReactNode | React.ReactNode[];
};
const SelectOptions: React.FC<SelectOptionsProps> = ({
  className,
  show,
  placement,
  footer,
  showInPortal,
  children
}) => {
  const { setFloating, floatingStyles, getFloatingProps } = useSelect();

  const contents = (
    <div
      className={classNames(
        "tw-z-[9000] tw-overflow-hidden tw-rounded-xl tw-border tw-border-neutral-200 tw-shadow-lg",
        {
          "tw-hidden": !show,
          "tw-absolute tw-left-0 tw-top-full tw-float-left tw-block tw-w-max tw-bg-white": show,
          "animate animate-slide-up tw-bottom-full": placement === "above",
          "animate animate-slide-down tw-top-full": placement === "below"
        },
        className
      )}
      ref={setFloating}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      <ul className="tw-max-h-[300px] tw-flex-1 tw-overflow-auto" data-type="dropdown">
        {children}
      </ul>
      {footer && <div className="tw-border-t tw-border-neutral-100">{footer}</div>}
    </div>
  );

  return showInPortal ? <FloatingPortal>{contents}</FloatingPortal> : contents;
};

type SelectOptionProps = {
  className?: string;
  value: Option;
  disabled?: boolean;
  deletable?: boolean;
  children: React.ReactNode;
  isActive?: boolean;
  isSelected?: boolean;
  onClick: (value: Option) => void;
  onDelete?: (value: Option) => void;
};
export const SelectOption: React.FC<SelectOptionProps> = ({
  children,
  value,
  disabled,
  deletable,
  className,
  isActive,
  isSelected,
  onClick,
  onDelete
}) => {
  const ref = React.useRef<HTMLLIElement>(null);
  const [isDeleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    if (isActive) ref.current?.scrollIntoView({ block: "nearest" });
  }, [isActive]);

  const handleDeleteOption = async () => {
    setDeleting(true);
    await onDelete?.(value);
    setDeleting(false);
  };

  return (
    <li
      className={classNames(
        className,
        "tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-justify-between tw-px-6 tw-py-3",
        {
          "tw-bg-neutral-200": isActive,
          "tw-cursor-pointer  tw-text-neutral-900 hover:tw-bg-neutral-50 active:tw-bg-neutral-100": !disabled,
          "!tw-pointer-events-none tw-text-neutral-300": disabled
        }
      )}
      onClick={() => onClick(value)}
      data-type="dropdown-item"
      ref={ref}
    >
      <div className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-text-sm">{children}</div>
      {deletable && !isDeleting && (
        <TrashCanIcon
          className="tw-h-4 tw-w-4 tw-cursor-pointer tw-text-neutral-600 hover:tw-text-destructive-600"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteOption();
          }}
        />
      )}
      {deletable && isDeleting && <LoadingIcon className="tw-h-4 tw-w-4 tw-text-neutral-600" />}

      {!deletable && isSelected && <CheckmarkIcon />}
    </li>
  );
};
