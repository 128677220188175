import React from "react";
import classNames from "classnames";

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@components/dropdown";

const FONT_SIZES = Array.from(Array(27), (_, i) => (i + 1) * 8);

interface FontSizeDropdownProps {
  value: number;
  disableTabIndex?: boolean;
  className?: string;
  inputClassName?: string;
  onChange: (fontSize: number) => void;
}

export const FontSizeDropdown: React.FC<FontSizeDropdownProps> = ({
  value,
  disableTabIndex,
  className,
  inputClassName,
  onChange
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isFocused, setFocused] = React.useState(false);

  const renderOptions = FONT_SIZES.map((fontSize) => {
    return (
      <DropdownItem onClick={() => onChange(fontSize)} key={fontSize} selected={value === fontSize}>
        {fontSize}
      </DropdownItem>
    );
  });

  const handleClickDropdownButton = () => {
    if (!isFocused) {
      inputRef.current?.focus();
    }
  };

  return (
    <Dropdown>
      <DropdownButton
        onClick={handleClickDropdownButton}
        className={classNames("!tw-bg-neutral-50 focus-within:tw-shadow-focus-ring", className)}
      >
        <input
          className={classNames(
            "hover:tw-border-inherit tw-w-[69px] tw-rounded-sm tw-border-0 tw-bg-transparent tw-p-0 tw-font-medium tw-text-neutral-900 tw-outline-none [appearance:textfield]",
            inputClassName
          )}
          type="number"
          value={value}
          tabIndex={disableTabIndex ? -1 : 0}
          onChange={(e) => onChange(Number(e.target.value))}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          ref={inputRef}
        />
      </DropdownButton>

      <DropdownMenu className="tw-max-h-[320px] !tw-min-w-[135px] tw-overflow-auto" placement="bottom-start">
        {renderOptions}
      </DropdownMenu>
    </Dropdown>
  );
};
