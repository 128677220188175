import React from "react";
import classNames from "classnames";

interface LoadingIconProps {
  loading?: boolean;
  primary?: boolean;
  className?: string;
}
export const LoadingIcon: React.FC<LoadingIconProps> = ({ loading = true, primary, className }) => {
  if (!loading) {
    return null;
  }

  return (
    <span
      className={classNames("bootstrap-spinner-border bootstrap-spinner-border-sm", className, {
        "tw-text-white": primary
      })}
    ></span>
  );
};
