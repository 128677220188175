import React from "react";

import { toggleFavouriteFolder } from "@frontend/api/dashboard.service";
import { MoveFolderModal } from "@frontend/containers/modals/move-folder-modal";

import { Dropdown, DropdownItem, DropdownMenu } from "@components/dropdown";
import {
  DeleteBinLineIcon,
  EditLineIcon,
  ShareForwardLineIcon,
  StarLineIcon,
  UnfavouriteIcon
} from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { ModalType } from "@core/interfaces/modal-type";
import { Folder } from "@core/state/dashboard/dashboard.store";
import { ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

import { RenameFolderModal } from "../../dashboard-nav/rename-folder-modal";
import { DeleteFolderModal } from "../modals/delete-folder-modal";

type PrivateFolderMenuProps = {
  folder: Folder;
  children: React.ReactNode;
  onSaving?: (isSaving: boolean) => void;
  onToggleDropdown?: (isOpen: boolean) => void;
  onChange?: () => void;
};

export const PrivateFolderMenu: React.FC<PrivateFolderMenuProps> = ({
  folder,
  children,
  onChange,
  onToggleDropdown,
  onSaving
}) => {
  const { currentAccount } = useAccounts();
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.PrivateFolder);
  const { activeFolder, selectFolder } = useDashboard();

  const isRootFolder = !Boolean(folder.parentId);
  const canUpdateFolder = permissions[ResourceAction.Update];
  const canDeleteFolder = permissions[ResourceAction.Delete];
  const canMoveFolder = permissions[ResourceAction.Move];
  const canFavourite = permissions[ResourceAction.Favourite];

  if (isRootFolder || (!canUpdateFolder && !canDeleteFolder && !canMoveFolder && !canFavourite)) {
    return null;
  }

  const handleToggleFavourite = async () => {
    onSaving?.(true);
    try {
      await toggleFavouriteFolder(folder.id);
      await onChange?.();
    } catch (e) {
      console.error(e);
    } finally {
      onSaving?.(false);
    }
  };

  const handleMove = () => {
    const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveFolder);

    showMoveModal(
      <MoveFolderModal
        folder={folder}
        closeModal={hideMoveModal}
        onBeforeSave={() => onSaving?.(true)}
        onCompleteSave={async () => {
          await onChange?.();
          onSaving?.(false);
        }}
      />
    );
  };

  const handleDelete = () => {
    const [showDeleteFolderModal, hideDeleteFolderModal] = useModal(ModalType.DeleteFolder);

    const isDeletingActiveFolder = activeFolder?.id === folder.id;
    const nextFolderId = isDeletingActiveFolder ? activeFolder?.parentId : null;

    showDeleteFolderModal(
      <DeleteFolderModal
        folder={folder}
        closeModal={hideDeleteFolderModal}
        onBeforeDelete={() => onSaving?.(true)}
        onCompleteDelete={() => {
          if (isDeletingActiveFolder) {
            selectFolder(nextFolderId);
          }
          onChange?.();
        }}
      />
    );
  };

  const handleRename = () => {
    const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameFolder);

    showRenameModal(<RenameFolderModal folder={folder} hideModal={hideRenameModal} onCompleteSave={onChange} />);
  };

  return (
    <Dropdown>
      {children}

      <DropdownMenu
        placement="right-start"
        showInPortal
        onOpen={() => onToggleDropdown?.(true)}
        onClose={() => onToggleDropdown?.(false)}
      >
        {canFavourite && (
          <DropdownItem
            onClick={handleToggleFavourite}
            lIcon={
              folder.isFavourite ? (
                <UnfavouriteIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />
              ) : (
                <StarLineIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />
              )
            }
            className="tw-font-medium"
          >
            {folder.isFavourite ? "Remove from favourites" : "Add to favourites"}
          </DropdownItem>
        )}
        {canUpdateFolder && (
          <DropdownItem
            onClick={handleRename}
            lIcon={<EditLineIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />}
            className="tw-font-medium"
          >
            Rename folder
          </DropdownItem>
        )}
        {canMoveFolder && (
          <DropdownItem
            onClick={handleMove}
            lIcon={<ShareForwardLineIcon className="tw-h-5 tw-w-5 tw-shrink-0" />}
            className="tw-font-medium "
          >
            Move folder
          </DropdownItem>
        )}
        {canDeleteFolder && (
          <DropdownItem
            onClick={handleDelete}
            lIcon={<DeleteBinLineIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-destructive-600" />}
            className="tw-font-medium !tw-text-destructive-600"
          >
            Delete folder
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
