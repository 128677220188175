import React from "react";

import { DeleteMediaModal } from "@frontend/containers/modals/delete-media-modal";
import { DuplicateMediaModal } from "@frontend/containers/modals/duplicate-media-modal";
import { MoveMediaModal } from "@frontend/containers/modals/move-media-modal";
import { RenameMediaModal } from "@frontend/containers/modals/rename-media-modal";

import { DropdownActionIconButton, DropdownItem, DropdownMenu } from "@components/dropdown";
import { More2LineIcon } from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";
import { useModal } from "@core/hooks/use-modal";
import { MediaListItem } from "@core/interfaces/media";
import { ModalType } from "@core/interfaces/modal-type";
import { ResourceType, RoleName } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

interface MediaCardDropdownProps {
  media: MediaListItem;
}

export const MediaCardDropdown: React.FC<MediaCardDropdownProps> = ({ media }) => {
  return <DropdownContent media={media} />;
};

export const DropdownContent: React.FC<MediaCardDropdownProps> = ({ media }) => {
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameMedia);
  const [showDuplicateModal, hideDuplicateModal] = useModal(ModalType.DuplicateMedia);

  const { currentAccount } = useAccounts();
  const { permissions: mediaPermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);
  const canRename = mediaPermissions.Update;
  const canMove = mediaPermissions.Move;
  const canDuplicate = mediaPermissions.Create;
  const canDelete = mediaPermissions.Delete;

  if (!currentAccount?.role || currentAccount?.role === RoleName.Viewer) {
    return;
  }

  return (
    <>
      <DropdownActionIconButton
        variant="for-white-background"
        size="20"
        icon={<More2LineIcon />}
        className="tw-bg-white"
      />

      <DropdownMenu className="tw-min-w-[180px]" placement="bottom-end">
        {canRename && (
          <DropdownItem
            onClick={() =>
              showRenameModal(
                <RenameMediaModal
                  mediaId={media.mediaId}
                  mediaName={media.name}
                  mediaLanguage={media.languageCode}
                  hideModal={hideRenameModal}
                />
              )
            }
          >
            Rename
          </DropdownItem>
        )}

        {canMove && (
          <DropdownItem
            onClick={() => showMoveModal(<MoveMediaModal mediaIds={[media.mediaId]} closeModal={hideMoveModal} />)}
          >
            Move...
          </DropdownItem>
        )}

        {canDuplicate && (
          <DropdownItem
            onClick={() =>
              showDuplicateModal(
                <DuplicateMediaModal
                  closeModal={hideDuplicateModal}
                  mediaId={media.mediaId}
                  mediaName={media.name}
                  mediaFolderId={media.folderId}
                  mediaFileSize={media.storageBytes}
                />
              )
            }
          >
            Duplicate
          </DropdownItem>
        )}
        {canDelete && (
          <DropdownItem
            className="!tw-text-destructive-600"
            onClick={() =>
              showDeleteModal(<DeleteMediaModal mediaIds={[media.mediaId]} closeModal={hideDeleteModal} />)
            }
          >
            Delete file
          </DropdownItem>
        )}
      </DropdownMenu>
    </>
  );
};
