import React from "react";

import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownHeader, DropdownItem, DropdownMenu } from "@components/dropdown";
import { Checkbox } from "@components/form-controls";

import { useActiveMediaConfigState } from "@core/state/editor/editor.hooks";
import { ratioHelper, ratioLabel } from "@core/utils/aspect-ratio";
import { AspectRatio } from "@getsubly/common";
import { useDownloadDropdown } from "@media-editor/contexts/download-dropdown.context";

interface DownloadRatiosDropdownProps {
  disabled?: boolean;
}
export const DownloadRatiosDropdown: React.FC<DownloadRatiosDropdownProps> = ({ disabled }) => {
  const mediaConfig = useActiveMediaConfigState();
  const { ratios, toggleRatio } = useDownloadDropdown();

  const renderOptions = Object.values(AspectRatio).map((ratio) => {
    const isOriginal = ratio === mediaConfig?.originalRatio;
    const label = ratioLabel(ratio, isOriginal);
    const helper = ratioHelper(ratio);
    const isSelected = ratios.includes(ratio);

    // Hide original item if it has a defined AspectRatio
    if (!isOriginal && ratio === AspectRatio.Original) {
      return null;
    }

    return (
      <DropdownItem key={ratio} className="!tw-p-0" keepOpenOnClick>
        <Checkbox
          className="tw-w-full tw-px-6 tw-py-3"
          checked={isSelected}
          onChange={() => toggleRatio(ratio)}
          leftChildren={
            <div className="tw-flex tw-w-full tw-flex-col">
              {label}
              {helper && <span className="tw-text-xs tw-text-neutral-500">{helper}</span>}
            </div>
          }
          large
        />
      </DropdownItem>
    );
  });

  const getLabel = (): string => {
    if (ratios.length === 0) {
      return "Select aspect ratio(s)";
    } else if (ratios.length === 1) {
      return ratioLabel(ratios[0]);
    }

    return `${ratios.length} aspect ratios selected`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary" disabled={disabled}>
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-w-full">
        <DropdownHeader>Select one or more aspect ratios</DropdownHeader>
        {renderOptions}
        <DropdownItem className="hover:!tw-bg-[transparent]">
          <Button variant="primary" className="tw-w-full" size="36">
            Apply
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
