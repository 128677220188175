import React from "react";
import classNames from "classnames";

import { SelectDropdown } from "./components/select-dropdown";
import { SelectInput } from "./components/select-input";
import {
  filterGroups,
  filterOptions,
  findFirstAvailableOption,
  flattenInput,
  getNextActiveOption,
  getPrevActiveOption,
  transformInputToGroups
} from "./utils/select.utils";
import { SelectProvider, useSelect } from "./select.context";

export type Option = {
  label: string;
  value: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: any;
};

export interface Group {
  label: string | null;
  deletable?: boolean;
  options: Option[];
}

export type MultiSelectProps = {
  multiple: true;
  defaultValue?: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
};

export type SingleSelectProps = {
  multiple?: false;
  defaultValue?: string;
  value?: string;
  onChange?: (
    value: string | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extras?: any
  ) => void;
};

export type SelectSize = "48" | "44" | "40" | "36" | "32" | "28";

type SelectProps = {
  className?: string;
  dropdownClassName?: string;
  selectInputClassName?: string;
  size?: SelectSize;
  placeholder?: string;
  options?: Option[] | Group[];
  hasError?: boolean;
  dropdownFooter?: (props: { dismiss: () => void }) => React.ReactNode;
  keepOpenOnSelect?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  placement?: "above" | "below";
  formatGroupHeader?: (group: Group) => string | number | boolean | React.ReactNode | React.ReactNode[];
  formatOptionLabel?: (option: Option, group: Group) => string | number | boolean | React.ReactNode | React.ReactNode[];
  formatInputSelectedLabel?: (option: Option) => string | number | boolean | React.ReactNode | React.ReactNode[];
  onDeleteOption?: (value: Option) => void;
  selectionLimit?: number;
  showInPortal?: boolean;
  variant?: "flat" | "outline";
} & (SingleSelectProps | MultiSelectProps);

export const Select: React.FC<SelectProps> = (props) => {
  return (
    <SelectProvider>
      <SelectContainer {...props} />
    </SelectProvider>
  );
};

export const SelectContainer: React.FC<SelectProps> = ({
  className,
  variant = "flat",
  dropdownClassName,
  selectInputClassName,
  size = "40",
  options = [],
  defaultValue,
  value,
  placeholder,
  multiple,
  hasError,
  dropdownFooter,
  keepOpenOnSelect,
  clearable,
  disabled,
  placement = "below",
  formatGroupHeader,
  formatOptionLabel,
  formatInputSelectedLabel,
  onChange,
  onDeleteOption,
  selectionLimit,
  showInPortal = true // defaulting to true as lots of components expect this when it was added
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const {
    isOpen,
    setOpen,
    searchQuery,
    setSearchQuery,
    selectedOptions,
    setSelectedOptions,
    activeOption,
    setActiveOption
  } = useSelect();

  const [stagedOptions, setStagedOptions] = React.useState(options);

  React.useEffect(() => {
    setStagedOptions(options);
  }, [options]);

  const flattenedOptions = React.useMemo(() => {
    return flattenInput(stagedOptions);
  }, [stagedOptions]);

  const groups = React.useMemo<Group[]>(() => {
    return transformInputToGroups(stagedOptions);
  }, [stagedOptions]);

  const filteredGroups = React.useMemo<Group[]>(() => {
    return filterGroups(groups, {
      excludeList: multiple ? selectedOptions : [],
      query: searchQuery
    });
  }, [groups, selectedOptions, searchQuery]);

  const filteredOptions = React.useMemo<Option[]>(() => {
    return filterOptions(flattenedOptions, {
      excludeList: multiple ? selectedOptions : [],
      query: searchQuery
    });
  }, [searchQuery, selectedOptions]);

  // states

  const canShowDropdown = isOpen && (filteredOptions.length > 0 || Boolean(dropdownFooter));
  const isClearable = clearable && selectedOptions.length > 0;
  const selectionLimitReached = Boolean(selectionLimit && selectedOptions.length >= selectionLimit);

  // actions

  const focusInput = () => {
    ref.current?.querySelector("input")?.focus();
  };

  // onMount

  React.useEffect(() => {
    if (!defaultValue) return;

    const defaultOptions: Option[] = filterOptions(flattenedOptions, {
      valueIncludeList: Array.isArray(defaultValue) ? defaultValue : [defaultValue]
    });

    handleSelectOptions(defaultOptions);
  }, []);

  // onChangeInput

  React.useEffect(() => {
    if (!value) return;

    const stagedSelectedOptions: Option[] = filterOptions(flattenedOptions, {
      valueIncludeList: Array.isArray(value) ? value : [value]
    });

    // only set selected options if selected options has changed
    const hasChanged =
      selectedOptions.length !== value.length ||
      !stagedSelectedOptions.every((stagedSelectedOption) =>
        selectedOptions.find((selectedOption) => stagedSelectedOption.value === selectedOption.value)
      );

    if (hasChanged) {
      setSelectedOptions(stagedSelectedOptions);
    }
  }, [value, options]);

  // onChange, emit selected option(s) and reset activeIndex to the first option

  const handleSelectOptions = (options: Option[]) => {
    setSelectedOptions(options);

    if (multiple) {
      onChange?.(options.map((option) => option.value));
    } else {
      onChange?.(options[0]?.value, options[0]?.extra);
      setActiveOption(options[0]);
    }
  };

  // navigation

  const handleNavUp = () => {
    if (flattenedOptions.length <= 0) return;

    if (!canShowDropdown) {
      setActiveOption(selectedOptions[0]);
      return setOpen(true);
    }

    const prevActiveOption = !activeOption
      ? findFirstAvailableOption(filteredOptions)
      : getPrevActiveOption(filteredOptions, activeOption);

    if (!prevActiveOption) return;

    setActiveOption(prevActiveOption);
  };

  const handleNavDown = () => {
    if (flattenedOptions.length <= 0) return;

    if (!canShowDropdown) {
      setActiveOption(selectedOptions[0]);
      return setOpen(true);
    }

    const nextActiveOption = !activeOption
      ? findFirstAvailableOption(filteredOptions)
      : getNextActiveOption(filteredOptions, activeOption);

    if (!nextActiveOption) return;

    setActiveOption(nextActiveOption);
  };

  // handle clicking inside/outside the component

  const handleClickInside = (e: React.MouseEvent) => {
    if (disabled) return;

    const clickedElement = e.target as HTMLElement;

    if (!isOpen) {
      setOpen(true);
      setActiveOption(selectedOptions[0]);
    } else if (clickedElement.closest(".select-toggle")) {
      setOpen(false);
    }
  };

  // handle selection

  const handleClickOption = (option: Option) => {
    focusInput();
    const isSingleInput = !multiple;
    const isMultiInput = multiple;

    if (isSingleInput) {
      handleSelectOptions([option]);
    } else if (isMultiInput) {
      const isSelected = selectedOptions.find((selectedOption) => selectedOption.value === option.value);

      // toggle clicked option
      const updatedSelectedOptions = isSelected
        ? selectedOptions.filter((selectedOption) => selectedOption.value !== option.value)
        : [...selectedOptions, option];

      handleSelectOptions(updatedSelectedOptions);
    }

    if (!keepOpenOnSelect) {
      setOpen(false);
    }

    setSearchQuery("");
  };

  // enter selects value, sets to search query and closes dropdown
  const handleEnterOption = () => {
    if (!canShowDropdown || !activeOption) return;

    const isSingleInput = !multiple;
    const isMultiInput = multiple;

    if (isSingleInput) {
      handleSelectOptions([activeOption]);
    } else if (isMultiInput) {
      const isSelected = selectedOptions.find((selectedOption) => selectedOption.value === activeOption.value);

      // toggle clicked option
      const updatedSelectedOptions = isSelected
        ? selectedOptions.filter((selectedOption) => selectedOption.value !== activeOption.value)
        : [...selectedOptions, activeOption];

      handleSelectOptions(updatedSelectedOptions);

      const filteredOptions = filterOptions(flattenedOptions, {
        excludeList: updatedSelectedOptions
      });

      setActiveOption(findFirstAvailableOption(filteredOptions));
    }

    if (!keepOpenOnSelect) {
      setOpen(false);
    }

    setSearchQuery("");
  };

  // store changed values
  const handleChange = (value: Option[]) => {
    if (multiple && Array.isArray(value)) {
      handleSelectOptions(value);
      if (activeOption) {
        setActiveOption(findFirstAvailableOption(filteredOptions));
      }
    } else if (!Array.isArray(value)) {
      setOpen(true);
      setSearchQuery("");
    }
  };

  const handleEscape = () => {
    setOpen(false);

    const isSingle = !multiple;

    if (isSingle && selectedOptions.length > 0) {
      setSearchQuery("");
    }
  };

  const handleSearch = (query: string) => {
    setOpen(true);
    setSearchQuery(query);
  };

  const handleClear = () => {
    if (clearable) {
      setSearchQuery("");
      handleSelectOptions([]);
    }
  };

  const handleTabAway = () => {
    setOpen(false);
  };

  const handleDismiss = () => {
    setOpen(false);
  };

  return (
    <div className={classNames(className, "tw-relative")} onClick={handleClickInside} ref={ref}>
      <SelectInput
        size={size}
        variant={variant}
        multiple={multiple}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        searchQuery={searchQuery}
        clearable={isClearable}
        activeOption={activeOption}
        isOpen={canShowDropdown}
        onNavUp={handleNavUp}
        onNavDown={handleNavDown}
        onEnter={handleEnterOption}
        onEscape={handleEscape}
        onChange={handleChange}
        onTabAway={handleTabAway}
        onSearch={handleSearch}
        onClear={handleClear}
        formatInputSelectedLabel={formatInputSelectedLabel}
        disabled={disabled}
        hasError={hasError}
        className={selectInputClassName}
      />
      <SelectDropdown
        className={dropdownClassName}
        show={canShowDropdown}
        placement={placement}
        groups={filteredGroups}
        activeOption={activeOption}
        selectedOptions={selectedOptions}
        selectionLimitReached={selectionLimitReached}
        formatGroupHeader={formatGroupHeader}
        formatOptionLabel={formatOptionLabel}
        footer={dropdownFooter}
        onClickOption={handleClickOption}
        onDeleteOption={onDeleteOption}
        onDismiss={handleDismiss}
        showInPortal={showInPortal}
      />
    </div>
  );
};
