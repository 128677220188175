import React from "react";

import { MediaCard } from "@frontend/components/media-card/media-card";

import { EnrichedMediaListItem } from "@core/interfaces/media";

interface MediaGridProps {
  mediaList: EnrichedMediaListItem[];
}
export const MediaGrid: React.FC<MediaGridProps> = ({ mediaList }) => {
  return (
    <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5">
      {mediaList.map((media) => (
        <MediaCard media={media} key={media.mediaId} />
      ))}
    </div>
  );
};
