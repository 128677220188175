import React from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { LongPressDetectEvents, useLongPress } from "use-long-press";

import { deleteMedia } from "@frontend/api/media.service";
import { EN } from "@frontend/assets/i18n/en";
import { ToolTip } from "@frontend/containers/tooltip/tooltip";
import { useSelectedFiles } from "@frontend/contexts/selected-files.context";
import { MEDIA_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@components/button";
import { useDropdown } from "@components/dropdown";
import { Checkbox } from "@components/form-controls";
import { FilmSnippetsIcon, OldSubtitleIcon, TranslationsIcon } from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";
import { useMediaTranscriptionProgress } from "@core/hooks/use-media-transcription-state";
import { usePlan } from "@core/hooks/use-plan";
import { EnrichedMediaListItem, MediaErrorReason, MediaJob, MediaListItem } from "@core/interfaces/media";
import { userPresenceQuery } from "@core/state/user-presence";
import { pluralize } from "@core/utils/strings";
import { JobStatus, JobType, MediaStatus } from "@getsubly/common";
import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useObservable } from "@mindspace-io/react";

import { notificationError } from "../notification";
import { ProfileImageIcon } from "../profile-image-icon/profile-image-icon";
import { ProgressBar } from "../progress-bar/progress-bar";
import { ThumbnailImage } from "../thumbnail-image/thumbnail-image";

import { MediaCardDropdown } from "./media-card-dropdown";

import styles from "./media-card.module.scss";

const statusForInProgressJob = (job: Pick<MediaJob, "type">): string => {
  switch (job.type) {
    case JobType.Upload:
      return "Uploading";

    case JobType.Conversion:
      return "Preparing your file...";

    case JobType.Transcribe:
      return "Transcribing subtitles";

    case JobType.HumanTranscribe:
      return "Human transcribing";

    default:
      return "";
  }
};

interface MediaCardTopProps {
  media: EnrichedMediaListItem;
  setDropdownOpen: (v: boolean) => void;
}
export const MediaCardTop: React.FC<MediaCardTopProps> = (props) => {
  const { media } = props;
  const job = media.latestJob;
  const transcriptionProgress = useMediaTranscriptionProgress();
  const { isOpen } = useDropdown();

  React.useEffect(() => {
    props.setDropdownOpen(isOpen);
  }, [isOpen]);

  if (!job) {
    return <MediaCardTopRegular {...props} />;
  }

  switch (job.status) {
    case JobStatus.Uploading:
    case JobStatus.Uploaded:
    case JobStatus.Pending:
    case JobStatus.Converting:
      return (
        <MediaCardTopWithProgress
          media={media}
          status={statusForInProgressJob(job)}
          uploadProgress={transcriptionProgress || job.percentage}
          loading
        />
      );

    case JobStatus.Failed:
      return <MediaCardTopWithError {...props} />;

    default:
      return <MediaCardTopRegular {...props} />;
  }
};

interface MediaCardTopWithProgressProps {
  media: EnrichedMediaListItem;
  status: string | JSX.Element;
  uploadProgress?: number;
  loading?: boolean;
}
const MediaCardTopWithProgress: React.FC<MediaCardTopWithProgressProps> = ({
  media,
  status,
  uploadProgress,
  loading
}) => {
  const isMediaReady = MediaStatus.Ready === media.status || media.isTranscribing;
  const mediaLink = isMediaReady ? `${MEDIA_PATH}/${media.mediaId}` : "#";
  const hasProgress = uploadProgress != null;

  return (
    <Link to={mediaLink} className={classNames({ "tw-cursor-default": !isMediaReady })}>
      <ThumbnailImage src={media.thumbnailUrl} alt={media.name} mediaType={media.type} />
      <div className={classNames(styles.toolbar, styles["toolbar--progress"])}>
        <p className="tw-mb-1 tw-text-center tw-text-sm tw-text-black">{status}</p>
        <h6 className="tw-mb-2 tw-text-center tw-text-h6">{hasProgress && `${uploadProgress}%`}</h6>
        <ProgressBar progress={uploadProgress ?? 0} loading={loading} />
      </div>
    </Link>
  );
};

const MediaCardTopRegular: React.FC<MediaCardTopProps> = ({ media }) => {
  const [activeUser] = useObservable(userPresenceQuery.selectActiveUser(media.mediaId));
  const { isViewer } = useAccounts();
  const { isFree } = usePlan();
  const navigate = useNavigate();
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();

  const isSelected = React.useMemo(() => selectedFiles.includes(media.mediaId), [selectedFiles.length, media.mediaId]);

  const toggleSelected = React.useCallback(() => {
    if (isSelected) {
      setSelectedFiles((ids) => [...ids].filter((id) => id !== media.mediaId));
    } else {
      setSelectedFiles((ids) => [...ids, media.mediaId]);
    }
  }, [isSelected, selectedFiles.length]);

  const handleLongPress = useLongPress(toggleSelected, {
    detect: LongPressDetectEvents.TOUCH
  });
  const isMediaReady = media.status === MediaStatus.Ready;

  const mediaLink = React.useMemo(() => {
    if (!isMediaReady || selectedFiles.length) {
      return "#";
    }

    return `${MEDIA_PATH}/${media.mediaId}`;
  }, [isMediaReady, selectedFiles.length]);

  const hasTranslations = media.translationLanguages?.length > 0;
  const snippets = media?.assConfig?.snippets?.length ?? 0;
  const hasSnippets = snippets > 0;

  const handleClickCard = () => {
    if (selectedFiles.length) {
      toggleSelected();
    } else {
      navigate(mediaLink);
    }
  };

  const renderMediaTopButton = () => {
    if (isSelected && selectedFiles.length) {
      return "Deselect";
    }

    if (!isSelected && selectedFiles.length) {
      return "Select";
    }

    if (isViewer || isFree) {
      return "View";
    }

    if (activeUser) {
      return "View only";
    }

    return "Edit";
  };

  return (
    <div onClick={handleClickCard} {...handleLongPress()}>
      <ThumbnailImage src={media.thumbnailUrl} alt={media.name} mediaType={media.type} />
      <div className={styles["badge-container"]}>
        {media.languageCode && <MediaTranscriptionBadge />}
        {hasTranslations && <MediaLanguagesBadge languagesCount={media.translationLanguages?.length} />}
        {hasSnippets && <MediaSnippetsBadge snippetsCount={snippets} />}
      </div>

      <div
        className={classNames(styles.toolbar, {
          [styles.toolbarSelected]: selectedFiles.length
        })}
      >
        <div className="tw-absolute tw-right-4 tw-top-4 tw-flex tw-flex-row tw-items-center tw-gap-4">
          <Checkbox onClick={(e) => e.stopPropagation()} onChange={() => toggleSelected()} checked={isSelected} large />
          {!selectedFiles.length && <MediaCardDropdown media={media} />}
        </div>

        <Button
          variant="secondary"
          type="link"
          className={classNames(styles["edit-button"], {
            [styles["pick-button"]]: selectedFiles.length
          })}
          to={mediaLink}
          size="36"
        >
          {renderMediaTopButton()}
        </Button>
      </div>
    </div>
  );
};

interface MediaCardTopWithErrorProps {
  media: MediaListItem;
}
const MediaCardTopWithError: React.FC<MediaCardTopWithErrorProps> = ({ media }) => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteMedia(media.mediaId);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  if (media.reason === MediaErrorReason.InsufficientFunds) {
    return (
      <div className={classNames(styles.toolbar, styles["toolbar--error"])}>
        <div className="tw-mb-1 tw-flex tw-w-full tw-flex-col tw-overflow-auto">
          <ErrorHeader label="Insufficient Funds" />
          <p className="tw-m-0 tw-text-xs tw-text-black">{EN.media[MediaErrorReason.InsufficientFunds]}</p>
        </div>
        <div className="tw-mt-4 tw-flex tw-w-full tw-justify-between">
          <Button
            variant="destructive"
            className={classNames("tw-mr-2", styles["error-delete"])}
            onClick={handleDelete}
            size="36"
          >
            Delete
          </Button>
          <Button
            variant="primary"
            type="link"
            to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
            size="36"
          >
            Upgrade
          </Button>
        </div>
      </div>
    );
  }
  const errorMessage =
    media.reason === MediaErrorReason.UploadCancelled
      ? EN.media[MediaErrorReason.UploadCancelled]
      : EN.media[MediaErrorReason.ProcessingCancelled];

  return (
    <div className={classNames(styles.toolbar, styles["toolbar--error"])}>
      <div className="tw-flex tw-w-full tw-flex-col tw-overflow-auto">
        <ErrorHeader label="Error" />
        <p className="tw-m-0 tw-text-xs tw-text-black">{errorMessage}</p>
      </div>
      <div className="tw-mt-2 tw-flex tw-w-full tw-items-end tw-justify-between">
        <Button
          variant="destructive"
          className={styles["error-delete"]}
          onClick={handleDelete}
          loading={loading}
          size="36"
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

interface ErrorHeaderProps {
  label: string;
}
export const ErrorHeader: React.FC<ErrorHeaderProps> = ({ label }) => {
  return (
    <div className="tw-mb-2 tw-flex tw-items-center tw-gap-1">
      <Icon
        path={mdiAlertCircleOutline}
        size="20px"
        color="var(--color-destructive-600)"
        className="tw-flex-shrink-0"
      />
      <label className="tw-m-0">{label}</label>
    </div>
  );
};

interface MediaLanguagesBadgeProps {
  languagesCount: number;
}
const MediaLanguagesBadge: React.FC<MediaLanguagesBadgeProps> = ({ languagesCount }) => {
  return (
    <ToolTip text={`${languagesCount} ${pluralize(languagesCount, "language")}`} place="top">
      <div className={styles["language-badge"]}>
        <div className={styles.icon}>
          <TranslationsIcon />
        </div>
        <div className={styles.text}>{languagesCount}</div>
      </div>
    </ToolTip>
  );
};

const MediaTranscriptionBadge: React.FC = () => {
  return (
    <ToolTip text={"1 subtitle"} place="top">
      <div className={styles["language-badge"]}>
        <div className={styles.icon}>
          <OldSubtitleIcon />
        </div>
        <div className={styles.text}>1</div>
      </div>
    </ToolTip>
  );
};

interface MediaSnippetsBadgeProps {
  snippetsCount: number;
}

const MediaSnippetsBadge: React.FC<MediaSnippetsBadgeProps> = ({ snippetsCount }) => {
  return (
    <ToolTip text={`${snippetsCount} ${pluralize(snippetsCount, "snippet")}`} place="top">
      <div className={styles["snippet-badge"]}>
        <div className={styles.icon}>
          <FilmSnippetsIcon />
        </div>
        <div className={styles.text}>{snippetsCount}</div>
      </div>
    </ToolTip>
  );
};

interface MediaCardUserProps {
  media: MediaListItem;
}

export const MediaCardUser: React.FC<MediaCardUserProps> = ({ media }) => {
  const [activeUser] = useObservable(userPresenceQuery.selectActiveUser(media.mediaId));

  if (!activeUser) {
    return null;
  }

  return (
    <div className={styles["profile"]}>
      <ToolTip
        text={`Your teammate ${activeUser.name ?? ""} is currently viewing this video`}
        place={"top"}
        className={styles["tooltip"]}
      >
        <ProfileImageIcon name={activeUser.name ?? ""} />
      </ToolTip>
    </div>
  );
};
