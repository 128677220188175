import { editorUiStore, TranscriptionState } from "@core/state/editor-ui";
import { useObservable } from "@mindspace-io/react";
import { select } from "@ngneat/elf";

export function useMediaTranscriptionState(): TranscriptionState | null {
  const [transcription] = useObservable(editorUiStore.pipe(select((state) => state.transcription)));

  return transcription || null;
}

export function useMediaTranscriptionProgress(): number {
  const [progress] = useObservable(editorUiStore.pipe(select((state) => state.transcription?.progress)));

  return progress || 0;
}
