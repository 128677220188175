import React from "react";

import { Button } from "@components/button";
import { Dropdown, DropdownButton, DropdownHeader, DropdownItem, DropdownMenu } from "@components/dropdown";
import { Checkbox } from "@components/form-controls";

import { usePlan } from "@core/hooks/use-plan";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { useActiveMediaTranscriptionsMapState } from "@core/state/editor/editor.hooks";
import { FileLanguage, Language, translationLanguages } from "@getsubly/common";
import { useDownloadDropdown } from "@media-editor/contexts/download-dropdown.context";

export const LanguageDropdown: React.FC = () => {
  const { languages, toggleLanguage } = useDownloadDropdown();
  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);
  const transcriptionsMap = useActiveMediaTranscriptionsMapState();
  const { isTrial } = usePlan();

  if (!transcriptionsMap || !transcriptionsMap.originalSubtitles) {
    return null;
  }

  const hasSelected = (lang: Language): boolean => {
    return languages.some((l) => l.languageCode === lang.languageCode);
  };

  const originalLanguage = transcriptionsMap.originalSubtitles;
  const originalTranscription = transcriptionsMap.originalTranscription;

  const existingFileLanguages: FileLanguage[] = [
    originalLanguage,
    ...transcriptionsMap.subtitlesTranslations,
    ...transcriptionsMap.transcriptionTranslations
  ];

  if (originalTranscription) {
    existingFileLanguages.push(originalTranscription);
  }
  const existingLanguages: Language[] = existingFileLanguages
    .map(({ language, languageCode, countryCode, flagCode }) => ({
      language,
      languageCode,
      countryCode,
      flagCode
    }))
    .reduce((acc: Language[], current: Language) => {
      current.languageCode && !acc.some((item) => item.languageCode === current.languageCode) && acc.push(current);
      return acc;
    }, []);

  const possibleLanguages = [...translationLanguages]
    .reduce((acc: Language[], current) => {
      !acc.some((item) => item.languageCode === current.languageCode) && acc.push(current);
      return acc;
    }, [])
    .filter((l) => !existingLanguages.some((fl) => fl.languageCode === l.languageCode));
  const renderOption = (lang: Language) => {
    const isSelected = hasSelected(lang);

    const isOriginal = lang.languageCode === originalLanguage.fileId;
    const disabled = isTrial || (!hasPermission && !isOriginal);

    return (
      <DropdownItem key={lang.languageCode} className="!tw-p-0" disabled={disabled} keepOpenOnClick>
        <Checkbox
          className="tw-w-full tw-px-6 tw-py-3"
          checked={isSelected}
          onChange={() => toggleLanguage(lang)}
          leftChildren={
            <div className="tw-flex tw-w-full tw-flex-grow tw-gap-2">
              <span className={`fi fi-${lang.flagCode} tw-shrink-0`} />
              {lang.language}
            </div>
          }
          large
        />
      </DropdownItem>
    );
  };

  const langOptions = [...existingLanguages, ...possibleLanguages].map(renderOption);

  const getLabel = (): React.ReactNode => {
    if (languages.length === 0) {
      return "No language selected";
    } else if (languages.length === 1) {
      const option = languages[0];

      return (
        <>
          <span className={`fi fi-${option.flagCode} tw-shrink-0`} />
          {option.language}
        </>
      );
    }

    return `${languages.length} selected languages`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary">
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[375px]" placement="bottom-start">
        <DropdownHeader>Select one or more languages</DropdownHeader>
        <div className="tw-max-h-[200px] tw-overflow-y-auto">{langOptions}</div>

        <DropdownItem className="hover:!tw-bg-[transparent]">
          <Button variant="primary" className="tw-w-full" size="40">
            Apply
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
