export interface Asset {
  id: string;
  name: string;
  filename: string;
  extension: string;
  s3Uri: string;
  publicUrl?: string;
  sizeBytes: number;
  uploadedAt: Date;
  height: number;
  width: number;
  type: AssetType;
  metadata: { label: string };
}

export enum AssetType {
  LOGO = "logo",
  ARTWORK = "artwork",
  FONT = "font",
  BACKGROUND = "background"
}
