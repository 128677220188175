import React from "react";
import classNames from "classnames";

import { Avatar } from "@components/avatar/avatar";
import { LoadingIcon } from "@components/loading-icon";

import { mdiCamera } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./profile-image-icon.module.scss";

interface ProfileImageIconProps {
  name: string;
  label?: string;
  image?: string;
  size?: string;
  twoLetters?: boolean;
  className?: string;
  isUploader?: boolean;
  isUploading?: boolean;
}
export const ProfileImageIcon: React.FC<ProfileImageIconProps> = ({
  name,
  label,
  image,
  size = "32px",
  twoLetters = false,
  isUploader,
  isUploading,
  className
}) => {
  if (isUploader) {
    return (
      <div className="tw-group tw-flex tw-items-center tw-gap-4">
        <div
          className={classNames("tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full", {
            "tw-bg-[#f5f5f5]": !isUploading,
            "tw-bg-neutral-400": isUploading
          })}
        >
          {!isUploading ? (
            image ? (
              <Avatar size="40" image={image} withBorder={false} color="#000" className="tw-shrink-0" />
            ) : (
              <Icon path={mdiCamera} color="black" size="1.2rem" className="tw-opacity-60 group-hover:tw-opacity-80" />
            )
          ) : (
            <LoadingIcon primary />
          )}
        </div>
        <div
          className={classNames("tw-text-sm tw-font-semibold ", {
            "tw-text-primary-500 tw-opacity-80 group-hover:tw-opacity-100": !isUploading,
            "tw-text-neutral-500": isUploading
          })}
        >
          {label ?? "Upload photo"}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles["letter-icon"],
        "tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-900",
        className
      )}
      style={{ width: size, height: size }}
    >
      {image ? (
        <Avatar size="32" image={image} withBorder={false} color="#000" className="tw-shrink-0" />
      ) : (
        getInitials(name, twoLetters).toUpperCase()
      )}
    </div>
  );
};

export const getInitials = (name: string, twoLetters: boolean, withSFallback = true): string => {
  // If name is null / empty, use S for Subly
  if (withSFallback && !name?.length) {
    return "S";
  }

  const firstLetter = name[0];
  const allNames = name.split(" ");

  if (allNames.length > 1 && twoLetters) {
    const lastLetter = allNames[allNames.length - 1][0] ?? "";

    return `${firstLetter}${lastLetter}`;
  }

  return firstLetter;
};
