import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { downloadSubtitles } from "@frontend/api/file.service";
import { handleError } from "@frontend/api/handle-error";
import {
  BatchType,
  fetchMedia,
  IBatchAudio,
  IBatchSubtitle,
  IBatchTranscription,
  IBatchVideo,
  startBatchDownload
} from "@frontend/api/media.service";
import { useBrowserTab } from "@frontend/contexts/browser-tab.context";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Banner } from "@components/banner/banner";
import { Button } from "@components/button";
import { useDropdown } from "@components/dropdown";
import { Checkbox } from "@components/form-controls";
import { ArrowDownTrayIcon, InformationCircleFilledIcon } from "@components/icons";
import { SingleLineBanner } from "@components/single-line-banner/single-line-banner";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useDownloadQueue } from "@core/hooks/use-download-queue";
import { usePlan } from "@core/hooks/use-plan";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { downloadQueueStore, QueueFile, QueueFileStatus } from "@core/state/download-queue/download-queue.store";
import {
  useActiveMediaAudioDescriptionsState,
  useActiveMediaConfigState,
  useActiveMediaIdState
} from "@core/state/editor/editor.hooks";
import { editorStateRepository } from "@core/state/editor/editor.state";
import { generateFilename } from "@core/utils/media-functions";
import { getBurnQualityForPlan } from "@core/utils/plans";
import { FileType, hashSubtitles } from "@getsubly/common";
import {
  AudioFormat,
  MASTER_FILE,
  useDownloadDropdown,
  VideoFormat
} from "@media-editor/contexts/download-dropdown.context";
import { useEditorPanelState } from "@media-editor/state/media-editor.hooks";
import { mediaEditorStateRepository } from "@media-editor/state/media-editor.state";
import { EditorPanel } from "@media-editor/types";

import { NoSubsDownloadWarning, NoTranscriptionDownloadWarning } from "../warnings";

import { DownloadRatiosDropdown } from "./dropdowns/download-ratios-dropdown";
import { LanguageDropdown } from "./dropdowns/language-dropdown";
import { SnippetsDropdown } from "./dropdowns/master-snippets-dropdown";
import { AudioSelection, SubtitlesSelection, TranscriptionSelection } from "./dropdowns/subtitle-dropdown";
import { VideoFormatOptions } from "./dropdowns/video-formats-options";

export const DownloadMenu: React.FC<{ closeModal?: () => void }> = ({ closeModal }) => {
  const [hasVideo, setVideo] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSnippets, setShowSnippets] = React.useState<boolean>(false);

  const mediaId = useActiveMediaIdState();
  const mediaConfig = useActiveMediaConfigState();
  const { hasAudioDescriptions } = useActiveMediaAudioDescriptionsState();

  const { trackEventWithAuth, analyticsData } = useAnalyticsWithAuth();
  const { addFiles, batchId, hasFile, setVisible } = useDownloadQueue(mediaId);
  const { browserTabId } = useBrowserTab();
  const { toggleOpen, isOpen } = useDropdown();

  const { plan, isTrial } = usePlan();

  const {
    downloadWithSubtitles,
    setDownloadWithSubtitles,
    downloadWithWarning,
    setDownloadWithWarning,
    downloadWithAudioDescription,
    setDownloadWithAudioDescription,
    showDownloadWithDisclaimer,
    languages,
    subtitlesLanguages,
    transcriptionLanguages,
    hasSnippets,
    setSnippets,
    snippets,
    ratios,
    videoFormats,
    transcriptionFormats,
    subtitlesFormats,
    audioFormats,
    hasTranscription: hasTranscriptions,
    hasSubtitles
  } = useDownloadDropdown();

  const showDisclaimerBanner = showDownloadWithDisclaimer && downloadWithWarning;

  React.useEffect(() => {
    if (!isOpen) {
      setVideo(false);
    }
  }, [isOpen]);

  const addFilesToBatch = async (
    mediaId: string,
    batchFiles: Array<IBatchVideo | IBatchSubtitle | IBatchAudio | IBatchTranscription>,
    queueFiles: QueueFile[]
  ) => {
    if (!batchFiles.length) {
      return;
    }
    closeModal && closeModal();

    try {
      const res = await startBatchDownload(mediaId, batchFiles, batchId);

      // TODO: Do files arrive in order? Is there a better way to do this?
      const files = queueFiles.map((f, i) => ({
        ...f,
        id: res.jobs[i]?.id,
        outputFileId: res.jobs[i]?.outputs[0]
      }));

      addFiles(res.batchId, files);

      const videoFiles = batchFiles.filter((f) => f.batchType === BatchType.Video);
      const subtitleFiles = batchFiles.filter((f) => f.batchType === BatchType.Subtitle);
      const transcriptionFiles = batchFiles.filter((f) => f.batchType === BatchType.Transcription);

      if (videoFiles.length) {
        trackEventWithAuth("Download / Video", {
          quantity: videoFiles.length
        });
      }

      if (subtitleFiles.length || transcriptionFiles.length) {
        if (subtitleFiles.length) {
          trackEventWithAuth("Download / Subtitle", {
            quantity: subtitleFiles.length
          });
        }

        if (transcriptionFiles.length) {
          trackEventWithAuth("Download / transcription", {
            quantity: transcriptionFiles.length
          });
        }

        // Download subtitle/transcription files automatically
        for await (const file of files) {
          const batchFile = file.batchFile;

          if (batchFile.batchType !== BatchType.Subtitle && batchFile.batchType !== BatchType.Transcription) {
            continue;
          }

          if (!file.outputFileId) {
            continue;
          }

          downloadQueueStore.updateQueueJob(mediaId, file.id, {
            status: QueueFileStatus.Downloading,
            isProcessing: true
          });

          await downloadSubtitles(mediaId, file.outputFileId, `${file.name}.${batchFile.type}`, analyticsData);
          downloadQueueStore.updateQueueJob(mediaId, file.id, {
            status: QueueFileStatus.Complete,
            hasDownloaded: true,
            isProcessing: false
          });
        }
      }

      // Force visible the queue if it was hidden
      setVisible(true);

      await fetchMedia(mediaId);
    } catch (error) {
      handleError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    const media = editorStateRepository.media;

    const language = media?.transcriptions.originalSubtitles;
    const downloadLanguages = downloadWithSubtitles
      ? languages
      : [
          {
            fileId: "NO_LANGUAGE",
            language: "NO_LANGUAGE",
            languageCode: "NO_LANGUAGE"
          }
        ];
    const quality = getBurnQualityForPlan(plan, isTrial);

    if (!mediaId || !language || !mediaConfig) {
      return;
    }

    setLoading(true);

    // 1. Create a new array of files to append to queue
    const batchFiles: Array<IBatchVideo | IBatchSubtitle | IBatchAudio | IBatchTranscription> = [];
    const queueFiles: QueueFile[] = [];
    const duplicateFiles: string[] = [];
    const totalSnippets = [...snippets, MASTER_FILE];

    // 2. Check if it has mp3 selected download audio
    if (audioFormats.includes(AudioFormat.MP3)) {
      const name = generateFilename({
        name: media.name,
        code: language.languageCode
      });

      const batchFile: IBatchAudio = {
        batchType: BatchType.Audio,
        extension: AudioFormat.MP3,
        quality,
        metadata: {
          downloadName: name
        }
      };

      batchFiles.push(batchFile);

      const queueFile: QueueFile = {
        name,
        progress: 0,
        type: "audio",
        status: QueueFileStatus.Processing,
        hasDownloaded: false,
        isProcessing: false,
        batchFile
      };

      queueFiles.push(queueFile);

      trackEventWithAuth("Download", {
        language: language.languageCode,
        original: false,
        isAudio: media.type === FileType.Audio,
        isGoogleDrive: false,
        audioFile: true,
        hasSpeakerStyle: Boolean(mediaConfig.applySpeakerStyles),
        hasSpeakerLabel: Boolean(mediaConfig.applySpeakerLabels)
      });
    }

    // 2. Run through selected options if it has video
    totalSnippets.forEach((snippet) => {
      if (hasVideo) {
        ratios.forEach((ratio) => {
          // 2.1 For each ratio, run through languages
          downloadLanguages.forEach((language) => {
            const subtitleFileId = subtitlesLanguages.find((sl) => sl.languageCode === language.languageCode)?.fileId;

            const transcriptions = mediaEditorStateRepository.transcriptions;
            const langTranscription = subtitleFileId ? transcriptions?.[subtitleFileId] : [];

            // 2.1.1 Hash file without transcription
            const transcription = downloadWithSubtitles && langTranscription ? langTranscription : [];

            const hash = hashSubtitles(transcription, mediaConfig, ratio, language.languageCode, snippet.id);

            // 2.1.1. For each language, run through file formats
            videoFormats.forEach((format) => {
              const isSnippet = snippet.id !== MASTER_FILE.id;
              const name = generateFilename({
                name: isSnippet ? snippet.name : media.name,
                code: language.languageCode,
                ratio,
                format,
                noSubtitles: downloadWithSubtitles ? undefined : "(no subtitles)"
              });

              // 2.1.2. Check if hash exists in files, if yes, add it to the duplicates
              if (hasFile(hash)) {
                duplicateFiles.push(name);
                return;
              }

              const batchFile: IBatchVideo = {
                batchType: BatchType.Video,
                hash,
                ratio,
                quality,
                subtitleFileId,
                useOriginal: format === VideoFormat.Original,
                metadata: {
                  downloadName: name,
                  browserTabId,
                  languageCode: language.languageCode
                },
                snippetId: isSnippet ? snippet.id : undefined,
                noSubtitles: !downloadWithSubtitles,
                includeAudioDescription: downloadWithAudioDescription,
                addDisclaimer: downloadWithWarning
              };

              batchFiles.push(batchFile);

              const queueFile: QueueFile = {
                name,
                progress: 0,
                type: "medium",
                status: QueueFileStatus.Processing,
                hasDownloaded: false,
                isProcessing: false,
                batchFile
              };

              queueFiles.push(queueFile);
            });
          });
        });
      }

      // 3. Add transcription
      languages.forEach((language) => {
        const transcriptionLanguage = transcriptionLanguages.find((tl) => tl.languageCode === language.languageCode);
        // 3.1 For each selected transcription format
        transcriptionFormats.forEach((type) => {
          const isSnippet = snippet.id !== MASTER_FILE.id;

          const name = generateFilename({
            name: isSnippet ? snippet.name : media.name,
            code: language.languageCode,
            format: "transcription"
          });

          const batchFile: IBatchTranscription = {
            batchType: BatchType.Transcription,
            subtitleFileId: transcriptionLanguage?.fileId,
            type,
            language: transcriptionLanguage ?? language,
            hasNoLineBreaks: false,
            metadata: {
              downloadName: name,
              languageId: transcriptionLanguage?.fileId,
              languageCode: language.languageCode
            },
            snippetId: isSnippet ? snippet.id : undefined
          };

          batchFiles.push(batchFile);
          const queueFile: QueueFile = {
            name,
            progress: 0,
            type: "transcription",
            status: QueueFileStatus.Processing,
            hasDownloaded: false,
            isProcessing: false,
            batchFile
          };

          queueFiles.push(queueFile);
        });
      });
      // 4. Add subtitles
      languages.forEach((language) => {
        const subtitleLanguage = subtitlesLanguages.find((sl) => sl.languageCode === language.languageCode);
        // 4.1 For each selected subtitle format
        subtitlesFormats.forEach((type) => {
          const isSnippet = snippet.id !== MASTER_FILE.id;

          const name = generateFilename({
            name: isSnippet ? snippet.name : media.name,
            code: language.languageCode
          });

          const batchFile: IBatchSubtitle = {
            batchType: BatchType.Subtitle,
            subtitleFileId: subtitleLanguage?.fileId,
            type,
            language: subtitleLanguage ?? language,
            hasNoLineBreaks: false,
            metadata: {
              downloadName: name,
              languageId: subtitleLanguage?.fileId,
              languageCode: language.languageCode
            },
            snippetId: isSnippet ? snippet.id : undefined
          };

          batchFiles.push(batchFile);

          const queueFile: QueueFile = {
            name,
            progress: 0,
            type: "subtitle",
            status: QueueFileStatus.Processing,
            hasDownloaded: false,
            isProcessing: false,
            batchFile
          };

          queueFiles.push(queueFile);
        });
      });
    });
    if (showDisclaimerBanner) {
      trackEventWithAuth("Download / with disclaimer", {
        disclaimer: mediaConfig?.disclaimer
      });
    }
    if (downloadWithSubtitles) {
      trackEventWithAuth("Download / with subtitles");
    }

    await addFilesToBatch(mediaId, batchFiles, queueFiles);

    setLoading(false);
    toggleOpen(false);
    if (closeModal) {
      closeModal();
    }
  };

  const disableDownloadTranscription = Boolean(transcriptionFormats.length) && !hasTranscriptions;
  const disableDownloadSubtitles = Boolean(subtitlesFormats.length) && !hasSubtitles;
  const disableDownloadWithSubtitles = hasVideo && downloadWithSubtitles && !hasSubtitles;

  const disableDownload = () => {
    if (disableDownloadSubtitles && disableDownloadWithSubtitles) {
      return true;
    }

    if (!Boolean(languages.length)) {
      return true;
    }

    return (
      !hasVideo &&
      !Boolean(transcriptionFormats.length) &&
      !Boolean(subtitlesFormats.length) &&
      !Boolean(audioFormats.length)
    );
  };

  const handleSnippetToggle = () => {
    if (showSnippets) {
      setShowSnippets(false);
      setSnippets([]);
    } else {
      setShowSnippets(true);
      setSnippets(snippets ?? []);
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <p className="tw-text-md tw-font-medium tw-text-neutral-900">Languages</p>
        <LanguageDropdown />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-neutral-50/80 tw-p-3">
        <p className="tw-text-md tw-font-medium">Transcription</p>
        <TranscriptionSelection />
        {disableDownloadTranscription && <NoTranscriptionDownloadWarning />}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-neutral-50/80 tw-p-3">
        <p className="tw-text-md tw-font-medium">Subtitles</p>
        <SubtitlesSelection />
        {disableDownloadSubtitles && <NoSubsDownloadWarning />}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-neutral-50/80 tw-p-3">
        <p className="tw-text-md tw-font-medium">Audio options</p>
        <AudioSelection disabled={isTrial} />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded-md tw-bg-neutral-50/80 tw-p-3">
        <Checkbox large checked={hasVideo} onChange={(e) => setVideo(e.target.checked)} disabled={isTrial}>
          Video
        </Checkbox>
        <div className="tw-space-y-2">
          <p
            className={classNames("tw-font-medium", {
              "tw-opacity-60": !hasVideo
            })}
          >
            Formats
          </p>
          <DownloadRatiosDropdown disabled={!hasVideo || isTrial} />
        </div>
        <Checkbox
          disabled={!hasVideo || isTrial}
          checked={downloadWithSubtitles}
          onChange={(e) => setDownloadWithSubtitles(e.target.checked)}
        >
          Download with subtitles
        </Checkbox>
        {downloadWithSubtitles && <VideoFormatOptions disabled={!hasVideo || isTrial} />}
        {disableDownloadWithSubtitles && <NoSubsDownloadWarning />}
        {hasAudioDescriptions && (
          <Checkbox
            disabled={!hasVideo || isTrial}
            checked={downloadWithAudioDescription}
            onChange={(e) => setDownloadWithAudioDescription(e.target.checked)}
          >
            Download with audio description
          </Checkbox>
        )}
        {showDownloadWithDisclaimer && (
          <Checkbox
            disabled={!hasVideo || isTrial}
            checked={downloadWithWarning}
            onChange={(e) => setDownloadWithWarning(e.target.checked)}
          >
            Intro disclaimer
          </Checkbox>
        )}
        {showDisclaimerBanner && (
          <Banner
            description="The disclaimer will be added at the beginning of your downloaded video"
            type="information"
            hideClose
            icon={
              <div className="tw-flex tw-h-5 tw-w-5 tw-items-center">
                <InformationCircleFilledIcon className="tw-mr-1" />
              </div>
            }
          />
        )}
        {hasSnippets && (
          <>
            <Checkbox
              large
              disabled={!hasVideo || isTrial}
              checked={showSnippets}
              onChange={() => handleSnippetToggle()}
            >
              Snippets
            </Checkbox>

            {showSnippets && <SnippetsDropdown />}
          </>
        )}
        {isTrial && (
          <SingleLineBanner theme="soft-yellow" hideClose>
            <strong className="tw-mr-2 tw-font-medium">Downloads of audio and video are blocked</strong>{" "}
            <span className="tw-text-xs">
              <Link
                to={{
                  pathname: SETTINGS_BILLING_PATH,
                  search: "checkout=true"
                }}
                onClick={() => trackEventWithAuth("Editor / download / upgrade now")}
                className="tw-text-primary-500"
              >
                Upgrade now
              </Link>
              &nbsp;to unlock them
            </span>
          </SingleLineBanner>
        )}
      </div>
      <DownloadActions onClick={handleDownload} isDownloading={loading} disabled={disableDownload()} />
    </>
  );
};

interface DownloadActionsProps {
  onClick: () => Promise<void>;
  isDownloading: boolean;
  disabled?: boolean;
}
const DownloadActions: React.FC<DownloadActionsProps> = ({ onClick, isDownloading, disabled = false }) => {
  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);
  const { toggleOpen } = useDropdown();
  const { setEditorPanel } = useEditorPanelState();

  const handleClickDownload = async () => {
    if (hasPermission) {
      setEditorPanel(EditorPanel.Downloads);
      await onClick();
      toggleOpen(false);
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleClickDownload}
      loading={isDownloading}
      disabled={isDownloading || disabled}
      className="tw-mt-2"
      icon={<ArrowDownTrayIcon className="tw-mr-2 tw-h-5 tw-w-5" />}
    >
      Download
    </Button>
  );
};
