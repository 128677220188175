import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import {
  ActionIconButton,
  ActionIconButtonSize,
  ActionIconButtonVariant
} from "@components/action-icon-button/action-icon-button";
import { Button, ButtonProps, IconButton, IconButtonProps } from "@components/button";
import { Toggle } from "@components/form-controls/toggle";
import { ArrowDownSLineIcon, ArrowUpSLineIcon, CheckLineIcon } from "@components/icons/remix-icons";

import { AnyOnClickHandler } from "@core/interfaces/types";
import { AlignedPlacement, FloatingPortal } from "@floating-ui/react";

import { DropdownProvider, useDropdown } from "./dropdown.context";

export enum DropdownToggleType {
  TOGGLE,
  CHECKMARK
}

type DropdownProps = {
  children: React.ReactNode;
};
export const Dropdown: React.FC<DropdownProps> = ({ children }) => {
  return <DropdownProvider>{children}</DropdownProvider>;
};

interface DropdownMenuProps {
  className?: string;
  header?: React.ReactNode;
  placement?: AlignedPlacement;
  mainAxis?: number;
  crossAxis?: number;
  forceClose?: number;
  children: React.ReactNode;
  showInPortal?: boolean;
  animate?: "up" | "down" | "none";
  onOpen?: () => void;
  onClose?: () => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  className,
  header,
  placement,
  children,
  showInPortal,
  onOpen,
  onClose,
  mainAxis,
  crossAxis,
  animate = "down"
}) => {
  const {
    isOpen,
    toggleOpen,
    dropdownRef,
    dropdownPositionStyles,
    getDropdownRefProps,
    setPlacement,
    setMainAxis,
    setCrossAxis
  } = useDropdown();

  React.useEffect(() => {
    if (isOpen) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [isOpen]);

  React.useEffect(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  React.useEffect(() => {
    if (placement) {
      setPlacement(placement);
    }
  }, [placement]);

  React.useEffect(() => {
    if (mainAxis) {
      setMainAxis(mainAxis);
    }
  }, [mainAxis]);

  React.useEffect(() => {
    if (crossAxis) {
      setCrossAxis(crossAxis);
    }
  }, [crossAxis]);

  const dropdownContents = (
    <div
      className={classNames(
        "tw-rounded-md tw-border tw-border-neutral-200 tw-shadow-NEW-lg",
        {
          "tw-hidden": !isOpen,
          "tw-absolute tw-left-0 tw-top-full tw-z-[1000] tw-float-left tw-block tw-w-max tw-bg-white": isOpen,
          "animate animate-slide-up": animate === "up",
          "animate animate-slide-down": animate === "down"
        },
        className
      )}
      style={dropdownPositionStyles}
      ref={dropdownRef}
      {...getDropdownRefProps()}
    >
      {header}
      {children}
    </div>
  );

  return showInPortal ? <FloatingPortal>{dropdownContents}</FloatingPortal> : dropdownContents;
};

const DropdownChevron: React.FC = () => {
  const { isOpen } = useDropdown();

  if (isOpen) {
    return <ArrowUpSLineIcon className="tw-ml-auto tw-w-5 tw-shrink-0" />;
  }
  return <ArrowDownSLineIcon className="tw-ml-auto tw-w-5 tw-shrink-0" />;
};

interface DropdownButtonProps extends Omit<ButtonProps, "type" | "state" | "isBlank"> {
  className?: string;
  disabled?: boolean;
  showArrow?: boolean;
  isOpenClassName?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}
export const DropdownButton: React.FC<DropdownButtonProps> = ({
  variant = "dropdown",
  className,
  disabled = false,
  showArrow = true,
  isOpenClassName,
  children,
  onClick,
  ...props
}) => {
  const { isOpen, toggleOpen, buttonRef, getButtonRefProps } = useDropdown();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (disabled) {
      return;
    }

    toggleOpen((isOpen) => !isOpen);

    onClick?.(event);
  };

  return (
    <Button
      className={classNames(className, isOpen && isOpenClassName, "tw-group")}
      ref={buttonRef}
      {...getButtonRefProps()}
      onClick={(e) => handleClick(e)}
      state={(disabled && "disabled") || (isOpen && "active") || "default"}
      hideTooltip={isOpen}
      rIcon={showArrow ? <DropdownChevron /> : props.rIcon}
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};

interface DropdownIconButtonProps extends Omit<IconButtonProps, "type" | "state" | "isBlank"> {
  className?: string;
  disabled?: boolean;
  isOpenClassName?: string;
  isClosedClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
}
export const DropdownIconButton: React.FC<DropdownIconButtonProps> = ({
  className,
  disabled = false,
  isOpenClassName,
  isClosedClassName,
  onClick,
  ...props
}) => {
  const { isOpen, toggleOpen, buttonRef, getButtonRefProps } = useDropdown();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (disabled) {
      return;
    }

    toggleOpen((isOpen) => !isOpen);

    onClick?.(event);
  };

  return (
    <IconButton
      className={classNames(className, isOpen && isOpenClassName, !isOpen && isClosedClassName, "tw-group")}
      ref={buttonRef}
      {...getButtonRefProps()}
      onClick={(e) => handleClick(e)}
      state={(disabled && "disabled") || (isOpen && "active") || "default"}
      hideTooltip={isOpen}
      {...props}
    />
  );
};

interface DropdownActionIconButtonProps
  extends Omit<IconButtonProps, "type" | "state" | "isBlank" | "variant" | "size"> {
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  variant?: ActionIconButtonVariant;
  size?: ActionIconButtonSize;
}
export const DropdownActionIconButton: React.FC<DropdownActionIconButtonProps> = ({
  className,
  disabled = false,
  onClick,
  ...props
}) => {
  const { isOpen, toggleOpen, buttonRef, getButtonRefProps } = useDropdown();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (disabled) {
      return;
    }

    toggleOpen((isOpen) => !isOpen);

    onClick?.(event);
  };

  return (
    <ActionIconButton
      className={classNames(className, "tw-group")}
      ref={buttonRef}
      {...getButtonRefProps()}
      onClick={(e) => handleClick(e)}
      state={(disabled && "disabled") || (isOpen && "focus") || "default"}
      hideTooltip={isOpen}
      {...props}
    />
  );
};

type DropdownItemProps = {
  className?: string;
  onClick?: AnyOnClickHandler;
  disabled?: boolean;
  keepOpenOnClick?: boolean;
  selected?: boolean;
  children: React.ReactNode;
  lIcon?: React.ReactNode;
  checked?: boolean;
  showToggle?: DropdownToggleType;
} & (
  | {
      to: string;
      state?: unknown;
      target?: string;
    }
  | {
      to?: never;
      state?: never;
      target?: never;
    }
);
export const DropdownItem: React.FC<DropdownItemProps> = ({
  className,
  onClick,
  disabled = false,
  keepOpenOnClick = false,
  selected = false,
  to,
  state,
  target,
  lIcon,
  checked,
  children,
  showToggle
}) => {
  const { toggleOpen } = useDropdown();

  const handleClick: AnyOnClickHandler = (e, props) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(props);
    }

    if (!keepOpenOnClick) {
      toggleOpen(false);
    }
  };

  const renderLIcon = React.useMemo(() => {
    if (lIcon) {
      // return <div className="tw-flex tw-h-5 tw-w-5 tw-items-center">{lIcon}</div>;
      return lIcon;
    }
    return undefined;
  }, [lIcon]);

  const renderToggle = React.useMemo(() => {
    switch (showToggle) {
      case DropdownToggleType.CHECKMARK:
        return (
          <CheckLineIcon
            className={classNames(
              "tw-ml-auto tw-h-5 tw-w-5 tw-text-neutral-500 tw-opacity-0",
              { "group-hover/button:tw-opacity-50": !checked && !disabled },
              { "tw-opacity-100": checked }
            )}
          />
        );

      case DropdownToggleType.TOGGLE:
        return <Toggle checked={checked} containerClassName="tw-pointer-events-none tw-ml-auto" />;

      default:
        return undefined;
    }
  }, [showToggle, checked, disabled]);

  if (to) {
    return (
      <Link
        to={to}
        state={state}
        target={target}
        onClick={(e) => handleClick(e)}
        className={classNames(
          "tw-group/button tw-flex tw-min-h-[44px] tw-items-center tw-gap-2 tw-px-4 tw-py-[10px] tw-text-md first:tw-rounded-t-sm last:tw-rounded-b-sm",
          className,
          {
            "tw-bg-neutral-200": selected,
            "tw-cursor-pointer tw-text-neutral-900 hover:tw-bg-neutral-50 active:tw-bg-neutral-100 ": !disabled,
            "tw-cursor-not-allowed tw-bg-neutral-100 tw-text-neutral-300": disabled
          }
        )}
      >
        {renderLIcon}
        {children}
        {renderToggle}
      </Link>
    );
  }

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={classNames(
        "tw-group/button tw-flex tw-min-h-[44px] tw-items-center tw-gap-2 tw-px-4 tw-py-[10px] tw-text-md first:tw-rounded-t-sm last:tw-rounded-b-sm",
        className,
        {
          "tw-bg-neutral-200": selected,
          "tw-cursor-pointer tw-text-neutral-900 hover:tw-bg-neutral-50 active:tw-bg-neutral-100 ": !disabled,
          "tw-cursor-not-allowed tw-bg-neutral-100 tw-text-neutral-300": disabled
        }
      )}
    >
      {renderLIcon}
      {children}
      {renderToggle}
    </div>
  );
};

interface DropdownHeaderProps {
  children: React.ReactNode;
}

export const DropdownHeader: React.FC<DropdownHeaderProps> = ({ children }) => {
  return <div className="tw-mx-6 tw-mt-6 tw-text-md tw-font-semibold">{children}</div>;
};

interface DropdownDividerProps {
  className?: string;
}

export const DropdownDivider: React.FC<DropdownDividerProps> = ({ className }) => {
  return <div className={classNames("tw-my-1 tw-border-b tw-border-neutral-200", className)} />;
};
