import axios, { Canceler } from "axios";

import { Media } from "@core/interfaces/media";
import { User } from "@core/interfaces/user";
import { Order } from "@getsubly/common";

import restApi, { cancelOrderResponse } from "./rest-api";

export const getOrderList = (options?: {
  limit?: number;
  skip?: number;
  order?: "asc" | "desc";
  sort?: "filename" | "status" | "status_date";
  mediaId?: string;
  q?: string | null;
}): { cancel: Canceler; response: Promise<any> } => {
  const { limit = 4, skip = 0, order, sort, mediaId, q } = options ?? {};

  const source = axios.CancelToken.source();

  const response = restApi.GET_v1_order_list({
    cancelToken: source.token,
    params: { skip, limit, order, sort, mediaId, q }
  });

  return {
    cancel: source.cancel,
    response
  };
};

export const getOrder = (orderId: string): { cancel: Canceler; response: Promise<Order<User, Media>> } => {
  const source = axios.CancelToken.source();
  const response = restApi.GET_v1_order(orderId, { cancelToken: source.token }).then(({ order }) => order);

  return {
    cancel: source.cancel,
    response
  };
};

export const cancelOrderRequest = (orderId: string): { cancel: Canceler; response: Promise<cancelOrderResponse> } => {
  const source = axios.CancelToken.source();
  const response = restApi.PUT_v1_cancel_order(orderId, { cancelToken: source.token });

  return {
    cancel: source.cancel,
    response
  };
};
