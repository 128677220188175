import React, { useEffect } from "react";
import classNames from "classnames";

import {
  createGlossaryRule,
  deleteGlossaryRule,
  getGlossaryRules,
  updateGlossaryRule
} from "@frontend/api/glossary.service";
import { EN } from "@frontend/assets/i18n/en";

import { Button } from "@components/button";
import { Input } from "@components/form-controls/input";
import { ArrowRightSLineIcon, DeleteBinFillIcon, EditFillIcon } from "@components/icons";
import { Select } from "@components/select";

import { useAccounts } from "@core/hooks/use-accounts";
import { GlossaryLanguageType, IGlossary } from "@core/interfaces/glossary";
import { CheckIcon } from "@heroicons/react/24/outline";

import { SettingsCard } from "../settings-card/settings-card";

interface TranslationGlossaryProps {
  disabled?: boolean;
  className?: string;
}
export const TranslationGlossary: React.FC<TranslationGlossaryProps> = (props) => {
  return (
    <SettingsCard>
      <SettingsCard.Header>
        <div>
          <div className="tw-mb-1">Translation glossary</div>
          <p className="tw-text-sm tw-font-normal tw-text-neutral-600">
            Set rules for how words and phrases are translated
          </p>
        </div>
      </SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        <TranslationGlossaryContent {...props} />
      </SettingsCard.Body>
    </SettingsCard>
  );
};

export const TranslationGlossaryContent: React.FC<TranslationGlossaryProps> = ({ disabled, className }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [sourceLanguage, setSourceLanguage] = React.useState(Object.entries(GlossaryLanguageType)[0][0]);
  const [sourceText, setSourceText] = React.useState("");

  const [targetLanguage, setTargetLanguage] = React.useState(Object.entries(GlossaryLanguageType)[1][0]);
  const [targetText, setTargetText] = React.useState("");

  const sourceTextError = sourceText.length > 250;
  const targetTextError = targetText.length > 250;

  useEffect(() => {
    if (disabled) return;
    getGlossaryRules();
  }, [disabled]);

  const handleSourceLanguageChange = (value: string | undefined) => {
    if (value) {
      const index = Object.entries(GlossaryLanguageType).findIndex((el) => el[0] === value);
      setSourceLanguage(Object.entries(GlossaryLanguageType)[index][0]);
    }
  };

  const handleTargetLanguageChange = (value: string | undefined) => {
    if (value) {
      const index = Object.entries(GlossaryLanguageType).findIndex((el) => el[0] === value);
      setTargetLanguage(Object.entries(GlossaryLanguageType)[index][0]);
    }
  };

  const onAddRule = async () => {
    setIsLoading(true);

    await createGlossaryRule({
      sourceLanguage,
      sourceWords: sourceText.trim(),
      targetLanguage,
      targetWords: targetText.trim()
    });

    setSourceText("");
    setTargetText("");
    setIsLoading(false);
  };
  return (
    <>
      <div
        className={classNames(
          className,
          "tw-flex tw-w-full tw-flex-col tw-items-start tw-justify-between tw-gap-2 md:tw-flex-row"
        )}
      >
        <div className="tw-flex tw-w-full tw-flex-row">
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-2">
            <div>Replace</div>
            <div className="tw-flex tw-flex-row tw-items-start tw-gap-3">
              <Select
                className="tw-min-w-0 tw-max-w-[160px]"
                defaultValue={Object.entries(GlossaryLanguageType)[1][0]}
                options={Object.entries(GlossaryLanguageType).map(([key, value]) => ({
                  value: String(key),
                  label: `${key} ${value}`
                }))}
                formatInputSelectedLabel={(option) => option.value.slice(0, 2)}
                disabled={isLoading}
                onChange={handleSourceLanguageChange}
              />
              <Input
                value={sourceText ?? ""}
                onChange={({ target }) => {
                  setSourceText(target.value);
                }}
                required
                disabled={disabled || isLoading}
                placeholder="Source text"
                wrapperClassName="tw-w-full"
                hasError={sourceTextError}
                helper={sourceTextError ? EN.error.MaxGlossaryTextLength : undefined}
              />
            </div>
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-flex-row">
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-2">
            <div>With</div>
            <div className="tw-flex tw-flex-row tw-items-start tw-gap-3">
              <Select
                className="tw-min-w-0 tw-max-w-[160px]"
                defaultValue={Object.entries(GlossaryLanguageType)[0][0]}
                options={Object.entries(GlossaryLanguageType).map(([key, value]) => ({
                  value: String(key),
                  label: `${key} ${value}`
                }))}
                formatInputSelectedLabel={(option) => option.value.slice(0, 2)}
                disabled={isLoading}
                onChange={handleTargetLanguageChange}
              />
              <Input
                value={targetText ?? ""}
                onChange={({ target }) => {
                  setTargetText(target.value);
                }}
                disabled={disabled || isLoading}
                required
                placeholder="Target text"
                wrapperClassName="tw-w-full"
                hasError={targetTextError}
                helper={targetTextError ? EN.error.MaxGlossaryTextLength : undefined}
              />
            </div>
          </div>
        </div>
        <Button
          variant="secondary"
          className="tw-min-w-[64px] md:tw-mt-8"
          onClick={onAddRule}
          loading={isLoading}
          loadingIconClassName="tw-mr-0"
          disabled={sourceTextError || targetTextError || !sourceText || !targetText}
          size="40"
        >
          {isLoading ? null : "Add"}
        </Button>
      </div>
      <div className="tw-mt-4 tw-overflow-auto">
        <GlossaryList />
      </div>
    </>
  );
};

export const GlossaryList: React.FC = () => {
  const { glossary } = useAccounts();
  if (glossary.length === 0) {
    return;
  }

  let currentSourceLanguage = "";
  let currentTargetLanguage = "";

  const glossaryArrays = glossary.reduce((acc: IGlossary[][], rule): IGlossary[][] => {
    const { sourceLanguage, targetLanguage } = rule;
    const languagesChanged = sourceLanguage !== currentSourceLanguage || targetLanguage !== currentTargetLanguage;

    if (languagesChanged || acc.length <= 0) {
      currentSourceLanguage = sourceLanguage;
      currentTargetLanguage = targetLanguage;

      return [...acc, [rule]];
    }
    acc[acc.length - 1].push(rule);
    return acc;
  }, []);

  const glossaryLists = glossaryArrays.map((glossary, i) => {
    const glossaryList = glossary.map((rule) => {
      const { sourceLanguage, targetLanguage } = rule;
      const languagesChanged = sourceLanguage !== currentSourceLanguage || targetLanguage !== currentTargetLanguage;

      if (languagesChanged) {
        currentSourceLanguage = sourceLanguage;
        currentTargetLanguage = targetLanguage;
      }

      return (
        <li key={rule.id} className="even:tw-bg-neutral-50">
          <GlossaryRule rule={rule} />
        </li>
      );
    });
    const sourceLanguageText = GlossaryLanguageType[currentSourceLanguage as keyof typeof GlossaryLanguageType];
    const targetLanguageText = GlossaryLanguageType[currentTargetLanguage as keyof typeof GlossaryLanguageType];

    return (
      <React.Fragment key={i}>
        <div className="tw-px-2 tw-py-2.5">
          {sourceLanguageText} - {targetLanguageText}
        </div>
        <ul className="tw-border-collapse tw-divide-y tw-divide-neutral-200 tw-rounded tw-border tw-border-neutral-200">
          {glossaryList}
        </ul>
      </React.Fragment>
    );
  });

  return <>{glossaryLists}</>;
};

type GlossaryRuleProps = {
  rule: IGlossary;
};

export const GlossaryRule: React.FC<GlossaryRuleProps> = ({ rule }) => {
  const { id, sourceLanguage, sourceWords, targetLanguage, targetWords } = rule;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [sourceText, setSourceText] = React.useState(rule.sourceWords);
  const [targetText, setTargetText] = React.useState(rule.targetWords);

  const onUpdateRule = async () => {
    setIsLoading(true);

    if (sourceText !== sourceWords || targetText !== targetWords) {
      const newSourceText = sourceText.trim();
      const newTargetText = targetText.trim();

      setSourceText(newSourceText);
      setTargetText(newTargetText);

      await updateGlossaryRule(id, {
        sourceLanguage,
        sourceWords: newSourceText,
        targetLanguage,
        targetWords: newTargetText
      });
    }

    setIsEdit(false);
    setIsLoading(false);
  };

  const onDeleteRule = async () => {
    setIsLoading(true);

    await deleteGlossaryRule(id);

    setIsLoading(false);
  };
  const sourceTextError = sourceText.length > 250;
  const targetTextError = targetText.length > 250;

  return isEdit ? (
    <div className="tw-items-top tw-flex tw-w-full tw-justify-between tw-gap-2 tw-p-2">
      <Input
        wrapperClassName="tw-w-full"
        value={sourceText}
        onChange={({ target }) => setSourceText(target.value)}
        disabled={isLoading}
        hasError={sourceTextError}
        helper={sourceTextError ? EN.error.MaxGlossaryTextLength : undefined}
      />
      <ArrowRightSLineIcon className="tw-mt-2.5 tw-h-5 tw-w-5 tw-flex-none tw-text-neutral-500" />
      <Input
        wrapperClassName="tw-w-full"
        value={targetText}
        onChange={({ target }) => setTargetText(target.value)}
        disabled={isLoading}
        hasError={targetTextError}
        helper={targetTextError ? EN.error.MaxGlossaryTextLength : undefined}
      />
      <Button
        className="tw-h-10 tw-w-16"
        variant="secondary"
        onClick={onUpdateRule}
        loading={isLoading}
        disabled={sourceTextError || targetTextError}
        size="40"
      >
        <CheckIcon className="tw-h-5 tw-w-5 tw-text-neutral-700" />
      </Button>
    </div>
  ) : (
    <div className="tw-flex tw-min-h-10 tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-p-4 tw-py-2">
      <div className="tw-w-full">{sourceText}</div>
      <ArrowRightSLineIcon className="tw-h-5 tw-w-5 tw-flex-none tw-self-center tw-text-neutral-500" />
      <div className="tw-w-full">{targetText}</div>
      <div className="tw-w-12 tw-flex-none">
        <EditFillIcon
          className="tw-mr-2 tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500"
          onClick={() => setIsEdit(true)}
        />
        <DeleteBinFillIcon className="tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500" onClick={onDeleteRule} />
      </div>
    </div>
  );
};
