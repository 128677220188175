import React from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

import { DashboardUploadModal } from "@frontend/containers/dashboard/dashboard-upload-modal/dashboard-upload-modal";

import { UploadCloud2LineIcon } from "@components/icons";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useFileUploadQueue } from "@core/hooks/use-file-upload-queue";
import { useModal } from "@core/hooks/use-modal";
import { ModalType } from "@core/interfaces/modal-type";
import { VirtualFolderId } from "@core/state/dashboard/dashboard.store";
import { ACCEPTED_DROPZONE_FORMATS } from "@core/utils/mime-types";

type DashboardDropzoneProps = { show: boolean; className?: string };

export const DashboardDropzone: React.FC<DashboardDropzoneProps> = ({ show, className }) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  const { isViewer } = useAccounts();
  const { handleAddFilesToUploadQueue } = useFileUploadQueue();
  const { selectedFolderId } = useDashboard();
  const showUploadCard = selectedFolderId !== VirtualFolderId.AllSharedWithMe;

  const onDrop = React.useCallback(
    async (droppedFiles: File[]) => {
      if (!isViewer && droppedFiles.length > 0) {
        handleAddFilesToUploadQueue(droppedFiles, selectedFolderId ?? undefined);

        const [showModal, hideModal] = useModal(ModalType.Upload);
        showModal(<DashboardUploadModal closeModal={() => hideModal()} />);
      }
    },
    [selectedFolderId]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop: (files) => onDrop(files),
    accept: ACCEPTED_DROPZONE_FORMATS,
    minSize: 0,
    multiple: true,
    noClick: true
  });

  if (!showUploadCard) {
    return null;
  }

  return (
    <div
      className={classNames(
        "tw-transition-100 tw-flex tw-items-center tw-justify-center tw-bg-white tw-p-4 tw-opacity-0 tw-transition",
        { "tw-opacity-100": show, "tw-pointer-events-none": !show },
        className
      )}
      {...getRootProps()}
    >
      <div
        className={classNames(
          "tw-flex tw-h-[calc(100vh_-_32px)] tw-max-h-screen tw-w-full tw-cursor-default tw-items-center tw-justify-center tw-space-y-5 tw-rounded-lg tw-border-[2px] tw-border-dashed tw-border-transparent tw-p-5",
          {
            "!tw-border-primary-700 tw-bg-primary-50": isDragAccept,
            "!tw-border-destructive-700 tw-bg-destructive-50": isDragReject
          }
        )}
      >
        <div className="tw-mx-auto tw-flex tw-max-w-md tw-flex-col tw-items-center tw-justify-center tw-gap-2">
          <input {...getInputProps()} ref={fileRef} />

          <div className="tw-flex tw-h-[98px] tw-w-[98px] tw-items-center tw-justify-center">
            <UploadCloud2LineIcon className="tw-text-neutral-600" />
          </div>

          <div className="tw-mb-4 tw-text-center tw-text-sm tw-text-neutral-600">Drop it like it's hot</div>
        </div>
      </div>
    </div>
  );
};
