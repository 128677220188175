import React from "react";

import { deleteAsset, uploadAsset } from "@frontend/api/assets.service";
import { EN } from "@frontend/assets/i18n/en";
import { LockFeature } from "@frontend/components/lock-feature/lock-feature";
import { notificationError } from "@frontend/components/notification";

import { Button } from "@components/button";
import { FileButton } from "@components/form-controls/file-input";
import { Select } from "@components/select";

import { fontNamesFor } from "@assets";
import { useAssets } from "@core/hooks/use-assets";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { AssetType } from "@core/interfaces/asset";
import { SublyPlan } from "@core/interfaces/billing";
import { useActiveMediaIdState, useCurrentMediaLanguage } from "@core/state/editor/editor.hooks";
import { AssStyle, CustomFontConfig } from "@getsubly/common";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

interface FontDropdownProps {
  value: string;
  onChange: (value: AtLeast<AssStyle, "font">) => void;
  className?: string;
}

export const FontDropdown: React.FC<FontDropdownProps> = ({ value, onChange, className }) => {
  const { hasPermission } = usePlanPermissions(SublyPlan.Premium);

  const { settings } = useMediaEditor();

  const { fontAssets } = useAssets();
  const mediaId = useActiveMediaIdState();

  const currentLanguage = useCurrentMediaLanguage();
  const [isUploading, setUploading] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFont = (font: string | undefined, extra?: any) => {
    if (!font) return;

    if (extra?.id && extra?.url && extra?.fontName) {
      const customFont: CustomFontConfig = {
        id: extra.id,
        url: extra.url,
        fontName: extra.fontName
      };

      onChange({ font, customFont });
    } else {
      onChange({ font });
    }
  };

  const handleUpload = async (file: File) => {
    setUploading(true);

    const asset = await uploadAsset({
      mediaId,
      file,
      assetType: AssetType.FONT
    });

    handleChangeFont(asset.name, {
      id: asset.id,
      url: asset.publicUrl ?? "",
      fontName: asset.name
    });

    setUploading(false);
  };

  const handleDeleteCustomFont = async (id: string) => {
    const font = fontAssets.find((font) => font.id === id)?.name;

    // if deleting selected custom font, reset to verdana
    if (font === value) {
      onChange({ font: "Verdana", customFont: undefined });
    }

    // delete the custom font
    try {
      await deleteAsset({
        mediaId,
        assetId: id
      });

      if (fontAssets.length <= 1) {
        setEditing(false);
      }
    } catch (error) {
      notificationError(EN.error.defaultMessage);
    }
  };

  return (
    <div>
      <Select
        value={value}
        className={className}
        options={[
          // custom fonts
          {
            label: `My fonts`,
            deletable: isEditing,
            options: hasPermission
              ? fontAssets.map((font) => {
                  return {
                    label: font.metadata?.label ?? font.name,
                    value: font.name,
                    extra: {
                      id: font.id,
                      url: font.publicUrl ?? "",
                      fontName: font.name
                    }
                  };
                })
              : []
          },
          // foreign fonts
          {
            label: `${currentLanguage?.language} fonts`,
            options: fontNamesFor(currentLanguage?.languageCode).map((font) => ({
              label: font,
              value: font
            }))
          },
          // latin fonts
          {
            label: null,
            options: fontNamesFor().map((font) => ({
              label: font,
              value: font
            }))
          }
        ]}
        formatGroupHeader={(group) => {
          // my fonts header
          if (group.label === "My fonts") {
            return (
              <div className="tw-flex tw-min-h-[44px] tw-items-center tw-justify-between tw-px-6 tw-py-3 tw-text-sm tw-text-neutral-900">
                <p className="tw-text-sm tw-font-medium">My Fonts</p>
                <Button
                  variant="ghost"
                  className="tw-p-0 tw-text-sm tw-font-medium tw-text-primary-500 hover:tw-text-primary-500"
                  onClick={() => setEditing(!isEditing)}
                  size="36"
                >
                  {isEditing ? "Done" : "Edit"}
                </Button>
              </div>
            );
          }
          // foreign fonts header
          if (group.label?.endsWith("fonts")) {
            return <p className="tw-mt-2 tw-px-6 tw-py-3 tw-text-sm tw-font-medium">{group.label}</p>;
          }

          return;
        }}
        formatOptionLabel={(option) => <span style={{ fontFamily: option.value }}>{option.label}</span>}
        onDeleteOption={({ extra }) => {
          if (extra?.id) {
            return handleDeleteCustomFont(extra?.id);
          }

          return;
        }}
        formatInputSelectedLabel={(option) => <span style={{ fontFamily: option.value }}>{option.label}</span>}
        dropdownFooter={() =>
          !settings.features.isIframe && (
            <LockFeature minPermission={SublyPlan.Premium}>
              <FileButton
                accept=".ttf" // Only allow ttf files for now
                onChange={handleUpload}
                loading={isUploading}
                variant="quaternary"
                className="tw-w-full tw-px-6 tw-py-3 tw-text-sm tw-font-medium !tw-text-primary-500 hover:!tw-bg-neutral-50 hover:!tw-text-primary-500"
              >
                {isUploading ? "Uploading..." : "+ Upload custom font"}
              </FileButton>
            </LockFeature>
          )
        }
        onChange={handleChangeFont}
      />
    </div>
  );
};
